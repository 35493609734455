import React from 'react'
import './Loader.css'
import { IMAGE_URL } from '../../api/API'

const Loader = () => {
  return (
    <div className='loading'>
      <div className='loading-content'>
        <img 
          className='loading-logo animate-pulse' 
          src={`${IMAGE_URL}/images/short_logo_white.svg`}
          alt="Loading logo"
        />
        {/* <p className='loading-text text-center'>Please Wait, Working on it...</p> */}
      </div>
    </div>
  )
}

export default Loader