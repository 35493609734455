import React, { useEffect, useState } from 'react';
import './style.css';
import DailyView from '../../components/Calennder/DailyView';
import WeeklyView from '../../components/Calennder/WeeklyView';
import MonthlyView from '../../components/Calennder/MonthlyView';
import { useDispatch, useSelector } from 'react-redux';
import { fetchScheduleData } from '../../store/features/scheduleData/scheduleDataSlice';
import { IMAGE_URL, IMAGE_URL_BACKEND } from '../../api/API';
import { clearPostState } from '../../store/features/post/postSlice';
import { Link } from 'react-router-dom';
import ResponsiveSchedule from '../../components/DropDown/ResponsiveSchedule';

function getWeekNumber(date) {
  // Create a new Date object with the same date as the input date
  const tempDate = new Date(date);
  // Set the month to January
  tempDate.setMonth(0);
  // Set the date to the first day of January
  tempDate.setDate(1);
  // Calculate the number of days between the input date and January 1st
  const days = Math.floor((date - tempDate) / 86400000);
  // Calculate the week number by dividing the total days plus the day of the week of January 1st by 7, and rounding up
  const weekNumber = Math.ceil((days + tempDate.getDay() + 1) / 7);
  // Return the week number
  return weekNumber;
}

function getWeekDatesForDate(date) {
  // Array to store the dates of the week
  const weekDates = [];
  // Calculate the start date of the week (Sunday)
  const weekStartDate = new Date(date);
  weekStartDate.setDate(date.getDate() - date.getDay());

  // Options for formatting the date
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  // Loop through each day of the week (7 days)
  for (let i = 0; i < 7; i++) {
    // Create a new date object for each day
    const day = new Date(weekStartDate);
    // Set the date to the current day of the week
    day.setDate(weekStartDate.getDate() + i);
    // Format the date and push it to the weekDates array
    weekDates.push(day.toLocaleDateString(undefined, options));
  }

  // Return the array of formatted dates for the week
  return weekDates;
}

function printAllDatesOfMonth(year, month) {
  // Calculate the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);

  // Get the total number of days in the month
  const daysInMonth = new Date(year, month + 1, 0).getDate();

  // Get the day of the week for the first day of the month
  const dayOfWeek = firstDayOfMonth.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Array to store the weeks of the month
  const weeks = [];

  // Initialize the current week with empty cells for days before the 1st day
  let currentWeek = Array(dayOfWeek).fill('');

  // Loop through each day of the month
  for (let day = 1; day <= daysInMonth; day++) {
    // Create a new date object for the current day
    const currentDate = new Date(year, month, day);

    // Format the date as 'MM/DD/YYYY'
    const formattedDate = currentDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    // Push the formatted date to the current week
    currentWeek.push(formattedDate);

    // If the current week is complete (7 days), push it to the weeks array and reset the current week
    if (currentWeek.length === 7) {
      weeks.push([...currentWeek]);
      currentWeek = [];
    }
  }

  // Push the last week if it's not complete (i.e., the month doesn't end on a Saturday)
  if (currentWeek.length > 0) {
    weeks.push([...currentWeek]);
  }

  // Return the array of weeks containing the formatted dates of the month
  return weeks;
}



const Calender = () => {
  const [hidden, setHidden] = useState({
    date: "block",
    time: "block",
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  const { scheduledData } = useSelector((state) => state.scheduleData);
  
  const userInfo = user?.userData
  // console.log('qqqq,');
  // useEffect(() => {

  // }, [scheduledData])

  // console.log({ scheduledData });
  const currentDate = new Date();
  const years = currentDate.getFullYear();
  const months = String(currentDate.getMonth() + 1).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${years}-${months}-${day}`;
  const filterdata = ['Monthly', 'Weekly', 'Daily'];
  const [selectedItem, setSelectedItem] = useState(filterdata[0]);
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [date, setDate] = useState('');
  const [weekNumber, setWeekNumber] = useState('');
  const [weekDates, setWeekDates] = useState([]);
  const [allDatesOfMonth, setAllDatesOfMonth] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [weekStartDate, setWeekStartDate] = useState('');
  const [getTime, setGetTime] = useState('');
  const [previewLay, setPreviewLay] = useState('')
  // console.log({ previewLay });
  const handleCanel = () => {
    setPreviewLay('')
  }
  const startDate = allDatesOfMonth[0]?.find((item) => item !== "")
  let endDate = allDatesOfMonth?.length > 0
    ? (allDatesOfMonth[allDatesOfMonth?.length - 1] && allDatesOfMonth[allDatesOfMonth?.length - 1]?.length > 0
      ? allDatesOfMonth[allDatesOfMonth?.length - 1][allDatesOfMonth[allDatesOfMonth?.length - 1]?.length - 1]
      : null)
    : null;
  // console.log({ allDatesOfMonth });
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setPreviewLay('')
  };
  //  console.log({startDate,endDate});
  useEffect(() => {
    // if user can select any date then findout year, month , date to fetch the calendar posts
    if (selectedDate) {
      // Create a Date object from the selectedDate
      const dateObject = new Date(selectedDate);

      // Update state variables with the year, month, date, week number, week dates, and all dates of the selected month
      setYear(dateObject.getFullYear());
      setMonth(dateObject.getMonth());
      setDate(dateObject.getDate());
      setWeekNumber(getWeekNumber(dateObject));
      setWeekDates(getWeekDatesForDate(dateObject));
      setAllDatesOfMonth(printAllDatesOfMonth(dateObject.getFullYear(), dateObject.getMonth()));

      // Calculate the start date of the week that contains the selected date
      const weekStartDate = new Date(year, month, date - dateObject.getDay());

      // Update state variable with the formatted start date of the week
      setWeekStartDate(weekStartDate.toLocaleDateString());

      // Calculate the dates of the week that contains the selected date
      const weekDates = getWeekDatesForDate(weekStartDate);

      // Update state variable with the dates of the selected week
      setSelectedWeek(weekDates);
    }
  }, [selectedDate, year, month, date]);

  // fetch schedule data according to change date,
  // first find out current month then calculate previous month of 25 to next month of 10 date 
  const handleDateChange = (e) => {
    const inputDate = e.target.value;
    setSelectedDate(inputDate);

    if (user.userData.linkedinId) {
      const dateObject = new Date(inputDate);
      let getStartYear = dateObject.getFullYear()
      let getEndYear = dateObject.getFullYear()
      let getMonthNumber = dateObject.getMonth();
      getMonthNumber = getMonthNumber < 10 ? `0${getMonthNumber}` : getMonthNumber
      if (getMonthNumber === '0-1') {
        getMonthNumber = 12
        getStartYear = getStartYear - 1
      } else if (getMonthNumber === `00`) {
        getMonthNumber = 12
        getStartYear = getStartYear - 1
      }


      let getEndMonthNumber = dateObject.getMonth() + 2;
      getEndMonthNumber = getEndMonthNumber < 10 ? `0${getEndMonthNumber}` : getEndMonthNumber

      if (getEndMonthNumber === 13) {
        getEndMonthNumber = `01`
        getEndYear = getEndYear + 1
      }

      // console.log({getMonthNumber,getEndMonthNumber})
      dispatch(fetchScheduleData({ userId: user.userData.linkedinId, startDate: `${getStartYear}-${getMonthNumber}-25`, endDate: `${getEndYear}-${getEndMonthNumber}-10` }));
    }
  };

  let componentToRender;
  switch (selectedItem) {
    case 'Daily':
      componentToRender = <DailyView scheduledData={scheduledData} selectedDate={selectedDate} setPreviewLay={setPreviewLay} />;
      break;
    case 'Weekly':
      componentToRender = <WeeklyView scheduledData={scheduledData} selectedWeek={selectedWeek} setPreviewLay={setPreviewLay} />;
      break;
    case 'Monthly':
      componentToRender = <MonthlyView scheduledData={scheduledData} allDatesOfMonth={allDatesOfMonth} setPreviewLay={setPreviewLay} />;
      break;
    default:
      componentToRender = null;
      break;
  }

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Initialize variables for start and end year
    let getStartYear = currentDate.getFullYear();
    let getEndYear = currentDate.getFullYear();

    // Get the current month number and format it with leading zeros if necessary
    let getMonthNumber = currentDate.getMonth();
    getMonthNumber = getMonthNumber < 10 ? `0${getMonthNumber}` : getMonthNumber;

    // Adjust start year and month if the current month is January
    if (getMonthNumber === '0-1') {
      getMonthNumber = 12;
      getStartYear = getStartYear - 1;
    } else if (getMonthNumber === `00`) {
      getMonthNumber = 12;
      getStartYear = getStartYear - 1;
    }

    // Calculate the end month number and format it with leading zeros if necessary
    let getEndMonthNumber = currentDate.getMonth() + 2;
    getEndMonthNumber = getEndMonthNumber < 10 ? `0${getEndMonthNumber}` : getEndMonthNumber;

    // Adjust end month and year if the end month is December
    if (getEndMonthNumber === 13) {
      getEndMonthNumber = `01`;
      getEndYear = getEndYear + 1;
    }

    // If the user's LinkedIn ID is available, dispatch a fetchScheduleData action
    if (user.userData.linkedinId) {
      dispatch(fetchScheduleData({ userId: user.userData.linkedinId, startDate: `${getStartYear}-${getMonthNumber}-25`, endDate: `${getEndYear}-${getEndMonthNumber}-10` }));
    }
  }, [user.userData.linkedinId]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="calender-container pe-3 ps-3">
        <div className='d-flex mb-3 flex-column'>
          <h3 className='text-xl'>Being consistent never been easier</h3>
          <p className='mt-3 text-base'>Manage <u><Link to='/my-accounts?aiConfig=0' className='text-blue-400'>Auto-Schedule</Link></u></p>
        </div>
        <div className="row mt-3">
          <div className={'col-lg-12'}>
            <div className="schedule-header">
            <ResponsiveSchedule 
  filterdata={filterdata} 
  isDark={isDark} 
  handleItemClick={handleItemClick} 
/>       
              <div className="schedule-right">
                <input type="date" value={selectedDate} onClick={(e) => {
                  e.target.type = "date";
                  e.target.showPicker();

                  setHidden({
                    ...hidden,
                    date: "none",
                  });
                }}
                  onBlur={(e) => {
                    e.target.type = "date";
                    setHidden({
                      ...hidden,
                      date: "block",
                    });
                  }} onChange={handleDateChange} className='form-control' />
                {/* <input type="time" name="getTime" onChange={(e) => setGetTime(e.target.value)} className='form-control' /> */}
              </div>
            </div>
            <div className={`schedule-data overflow-y-auto w-[100%] mt-3 ${isDark ? '' : 'card-containers-dark'}`}>
              {componentToRender}
            </div>
          </div>
          {/* <div className={previewLay ? 'col-lg-5 col-xxl-4 col-xl-4 ' : 'pre-col-hide'}>
            <div className='d-flex justify-content-between align-items-center'>
              <h4 className='pt-2' style={ isDark ? {color:'#000'} : {color:'#fff'}}>Preview</h4>
              <i className="fa-regular fa-eye-slash" style={ isDark ? {color:'#000'} : {color:'#fff'}} onClick={handleCanel}></i>
            </div>
            <div className={`rounded p-2 mt-5 prev-cont ${isDark ? '':'prev-cont-dark'}`}>
              <span className='cancel-btn' onClick={handleCanel} >X</span>
              
              <div className='userinfo'>
                <div className='d-flex align-items-center'>
                    {
                    user?.userPhoto?.length > 0
                        ? <img src={user?.userPhoto} className='avatar-img'/>
                        : <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className='avatar-img'/>
                    }
                  <div className='d-flex flex-column align-items-start user-profile'>
                      <p>{ userInfo?.userName?.length > 30 ? `${userInfo?.userName?.substring(0, 30)}...` : userInfo?.userName}</p>
                      <span style={{color:'gray',fontSize:'0.7rem'}}>{user?.userChosenTopics?.userPromtAIProfession?.length > 30 ? user?.userChosenTopics?.userPromtAIProfession?.slice(0,30)+'...' : user?.userChosenTopics?.userPromtAIProfession}</span>
                  </div>
                </div>
                <i className="fa-brands fa-linkedin fa-2x" style={{color: "#105ada"}}></i>
              </div>
              <hr />
              <div className='prev-content'>
                <p>{`${previewLay.postContent}`}</p>
                  {
                      (previewLay.originalUrl && previewLay.postType === 'IMAGE') &&
                      <img src={`${IMAGE_URL_BACKEND}/uploads/${previewLay.originalUrl}`} className='w-100' /> 
                  }
                  {
                      (previewLay.originalUrl && previewLay.postType === 'ARTICLE') &&
                      <p style={{margin:'0 0 8% 3%'}}><a href={previewLay.originalUrl} >{previewLay.originalUrl}</a> </p>
                  }
                  {
                      (previewLay.originalUrl && previewLay.postType === 'VIDEO') &&
                      <video src={`${IMAGE_URL_BACKEND}/uploads/${previewLay.originalUrl}`} className='w-100' controls />
                  }
              </div>
              <hr />
              <div className='prev-footer d-flex justify-content-between' >
                  <span className='p-2'><i className="fa fa-thumbs-up p-2" aria-hidden="true" style={{color:'#4267B2'}}></i> 227</span>
                  <span className='p-2'>3 comments . 4 reposts</span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Calender;
