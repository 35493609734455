import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserBookmark } from "../../store/features/bookmark/bookmarkSlice";
import {
  changeLayoutShift,
  isLoading,
} from "../../store/features/common/commonSlice";
import PostCard from "../../components/PostCard/DiscoverPostCard";
import { useState } from "react";
import { getApi } from "../../api/methods";
import { ColorRing } from "react-loader-spinner";
import "./Discover.css";
import InfiniteScroll from "react-infinite-scroll-component";
import PreviewPost from "../../components/PostIdea/PreviewPost/PreviewPost";
import { clearPostState } from "../../store/features/post/postSlice";
import { IMAGE_URL } from "../../api/API";
import axios from "axios";
import { toast } from "react-toastify";
import {
  addDiscoverData,
  fetchDiscoverCategory,
} from "../../store/features/discover/discoverSlice";
import searchImg from "../../assets/searchImg.svg";
import searchImgWhite from "../../assets/searchImgWhite.svg";
import { Input } from "../../components/ui/input";
import DiscoverDropDown from "../../components/DropDown/DiscoverDropDown";
// import { Input } from "../../components/ui/input"

const Discover = () => {
  const user = useSelector((state) => state.user);
  const post = useSelector((state) => state.post);
  const discover = useSelector((state) => state.discover);
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const { isDark, layoutShift } = useSelector((state) => state.common);
  const [data, setData] = useState(discover.discovers || []);
  const [dataFilter, setDataFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadings, setIsLoading] = useState(true);
  const [dataTotalLength, setDataTotalLength] = useState(null);
  // const [catagoryData, setCategoryData] = useState(discover.category || [])
  const filterdata = [...user?.userChosenTopics?.topics] || [];
  const [selectedItem, setSelectedItem] = useState([]);
  const [selectedItemLength, setSelectedItemLength] = useState(0);
  const [searchItem, setSearchItem] = useState("");
  const [searchItems, setSearchItems] = useState([]);
  const [searchItemsLength, setSearchItemsLength] = useState([]);
  const [filterItem, setFilterItem] = useState([]);
  const [loaderStatus, seLoaderStatus] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  //TODO:fix it
  // useEffect(()=>{
  //   dispatch(changeLayoutShift(false))
  // })

  const fetchAllCategory = async () => {
    dispatch(fetchDiscoverCategory());
    // try {
    //   const response = await getApi('discover/get-discover-category')
    //   setCategoryData(response.data)
    // } catch (err) {
    //   toast.error('All Catagory is not fetched!')
    // }
  };

  // seach functionality
  const fetchDataSearch = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `discover/get-search-by-category/${user.userData.linkedinId}?search=${selectedItem}&page=${page}`
      );
      // console.log({response});
      const newData = await response.data;
      setFilterItem((prevData) => [...prevData, ...newData]);
      setIsLoading(false);
      setDataTotalLength(response.totalLength);
      if (newData.length === 0 || newData.length < 12) {
        setHasMore(false);
        return;
      }
      setPage(page + 1);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // fetch all discover data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await getApi(
        `discover/get-discover-post/${user.userData.linkedinId}?page=${page}`
      );
      // console.log({response});
      const newData = await response.data;

      // removeDuplicates(allDuplicateCategory)
      // console.log({arrOfCategory,catagoryData});

      // console.log({ changeImg404, newData });

      setData((prevData) => {
        const temp = [...prevData, ...newData];
        return temp;
      });
      dispatch(addDiscoverData({ data: newData, isNew: false }));
      setIsLoading(false);
      setDataTotalLength(response.totalLength);
      if (newData.length === 0 || newData.length < 12) {
        setHasMore(false);
        return;
      }
      setPage(page + 1);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  // console.warn('🚀', {hasMore, isLoadings})
  // when user can click anyone category then fetch discover data according to select item
  // in mean time user can not select any other category
  const handleItemClick = async (item) => {
    // if (isLoadings) {
    //   toast.info("Loading, please wait!");
    //   return;
    // }

    if (selectedItem.includes(item)) {
      setSelectedItem([]);
      setFilterItem([]);
      setSearchItems([]);
      setPage(1);
      fetchData();
      setSearchItem("");
    } else {
      setSelectedItem([item]);
      setFilterItem(filterItem);
      setSearchItems(searchItems);
      setPage(1);
      setSearchItem("");
      setHasMore(true);
    }
  };

  // this function use to search data on the catagory basis
  const getSearchByCategory = async () => {
    // console.log({selectedItem})
    if (selectedItem?.length > 0) {
      dispatch(isLoading(true));
      try {
        const response = await getApi(
          `discover/get-search-by-category/${user.userData.linkedinId}?search=${selectedItem}&page=1`
        );
        setFilterItem(response.data);
        seLoaderStatus(false);
        dispatch(isLoading(false));
        if (response.data.length === 0) {
          setHasMore(false);
          return;
        }
        // dispatch(isLoading(false));
        // console.log({selected:response.data})
        // toast.success(`Fetched selected ${item} posts`)
      } catch (err) {
        dispatch(isLoading(false));
        toast.error("All Catagory posts are not fetched!");
      }
    } else {
      setData(discover.discovers);
    }
  };

  useEffect(() => {
    if (selectedItem.length > 0) {
      otgDataFilterHandler();
      // console.log({selectedItem});
      getSearchByCategory();
    }
  }, [selectedItem]);

  const otgDataFilterHandler = () => {
    const filterItems = data.filter((item) => {
      selectedItem.forEach((category) => {
        return item.category === category;
      });
    });
    setDataFilter(filterItems);
  };

  // useEffect(()=>{
  //   otgDataFilterHandler()
  //   if(selectedItem.length){
  //     setHasMore(false)
  //     seLoaderStatus(false)
  //   } else {
  //     setHasMore(true)
  //   }
  // },[selectedItem])

  const searchHandler = () => {
    const filterItems = data.filter((item) =>
      item.postContent?.toLowerCase().includes(searchItem)
    );
    setFilterItem(filterItems);
  };

  const handleSearchChange = (e)=>{
    setSearchItem(e.target.value);
  }

  useEffect(() => {
    // fetchData();
    // otgDataFilterHandler()
    // if (dataTotalLength > 0 && data.length > 0 && dataTotalLength === data.length) {
    //   setHasMore(false);
    // }
    seLoaderStatus(false);
  }, [page]);

  useEffect(() => {
    otgDataFilterHandler();
  }, [dataTotalLength]);

  // useEffect(() => {
  //   setDataFilter([])
  //   setSelectedItem([])

  //   if (searchItem.length === 0) {
  //     setFilterItem([])
  //   } else {
  //     searchHandler()
  //   }

  //   if (searchItem.length) {
  //     setHasMore(false)
  //     seLoaderStatus(false)
  //   } else {
  //     setHasMore(true)
  //   }
  // }, [searchItem])

  useEffect(() => {
    fetchAllCategory();
    if (data?.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    setPage(1);
    setSearchItems([]);

    if (searchItem?.length === 0) {
      setData(discover.discovers);
      setHasMore(true);
      setPage(1);
    }
  }, [searchItem]);

  // console.log({dataLength: data.length, dataTotalLength,data, dataFilter:dataFilter.length, filterItem:filterItem.length})

  // this is handle the search bar functionality
  const onKeywordSearchHandler = async () => {
    try {
      dispatch(isLoading(true));
      setIsLoading(true);

      let queryString;
      setPage(1);

      // here if user can select category or not the query accordingly hit to backend
      if (selectedItem?.length > 0) {
        queryString = `search=${searchItem}&category=${selectedItem[0]}&page=${page}`;
      } else {
        queryString = `search=${searchItem}&page=${page}`;
      }

      const response = await getApi(
        `discover/get-search-discover/${user.userData.linkedinId}?${queryString}`
      );
      // console.log({response});
      const newData = await response.data;

      // console.log({ changeImg404, newData });

      setSearchItems((prevData) => [...prevData, ...newData]);

      setIsLoading(false);
      dispatch(isLoading(false));
      if (newData.length === 0 || newData.length < 12) {
        setHasMore(false);
        return;
      }
      setPage(page + 1);
    } catch (error) {
      dispatch(isLoading(false));
      setIsLoading(false);
      console.error(error);
    }
  };

  // when user can type something on search bar then press enter to run
  const handlerEnterSearch = (e) => {
    if (e.keyCode === 13) {
      onKeywordSearchHandler();
    }
  };

  return (
    <div
      className={`flex flex-col gap-4 py-12 ${
        layoutShift ? "pl-24 pr-20" : "px-9 xs:px-20"
      }`}
    >
      <div className="flex flex-col gap-4">
        <div className="sm:flex justify-between items-center "
          style={{paddingLeft:isMobile? '15%': '0%'}}
        >
          <h3 className="text-sm sm:text-xl ">
            A mammoth library of posts to get inspired from!
          </h3>
          <div className="d-flex sm:justify-content-center align-items-center">
            <div className="flex">
              <Input
                className={`flex ${isDark?"text-black":"text-white"} h-9 rounded-md border border-input bg-transparent text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 p-3 w-64`}
                type="text"
                name="search"
                id=""
                placeholder="Search"
                value={searchItem}
                onKeyDown={handlerEnterSearch}
                onChange={handleSearchChange}
              />
              <button
                className="text-white relative -left-6"
                onClick={onKeywordSearchHandler}
                title="search"
              >
                <img src={isDark ? searchImg : searchImgWhite}></img>
                {/* <i className="fa-solid fa-magnifying-glass m-1 pb-3" onClick={onKeywordSearchHandler} title='search' style={ isDark ? {color:"black", fontSize:"1.5rem"} : {color:"white", fontSize:"1.5rem"}}></i> */}
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 mb-4">
          <div className="discover-topics d-flex justify-content-between pFix">
          <DiscoverDropDown
  discover={discover}
  selectedItem={selectedItem}
  handleItemClick={handleItemClick}
  common={common}
/>
          </div>
        </div>
        {/* <div className='card-containers m-0'> */}
        {isLoadings === false &&
          data?.length === 0 &&
          filterItem?.length === 0 &&
          searchItems?.length === 0 && (
            <h3 className="d-flex justify-content-center align-items-center w-100  mt-5">
              No post found!
            </h3>
          )}
        {searchItems?.length > 0 && searchItem && selectedItem.length === 0 && (
          <InfiniteScroll
            dataLength={searchItems?.length}
            next={onKeywordSearchHandler}
            hasMore={hasMore}
            style={{ overflow: "visible" }}
            loader={
              <div className="d-flex justify-content-center p-3">
                {isDark ? (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                  />
                ) : (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_white.svg`}
                  />
                )}
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="masonry-grid w-full -ml-2">
              {dataFilter?.length === 0 &&
                searchItems?.length > 0 &&
                searchItems?.map((item, i) => (
                  <>
                    <div className="masonry-grid-item" key={i}>
                      <PostCard
                        key={i}
                        des={item.postContent}
                        image={item.originalUrl}
                        postURL={item.url}
                        postype={item.postType}
                        data={item}
                        setData={setData}
                        isEvergreen={false}
                        isBookmark={true}
                        isDelete={false}
                      />
                    </div>
                  </>
                ))}
            </div>
          </InfiniteScroll>
        )}
        {searchItems?.length > 0 && selectedItem.length > 0 && searchItem && (
          <InfiniteScroll
            dataLength={filterItem?.length}
            next={fetchDataSearch}
            hasMore={hasMore}
            style={{ overflow: "visible" }}
            loader={
              <div className="d-flex justify-content-center p-3">
                {isDark ? (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                  />
                ) : (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_white.svg`}
                  />
                )}
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="masonry-grid w-full -ml-2">
              {dataFilter?.length === 0 &&
                searchItems?.length > 0 &&
                selectedItem.length > 0 &&
                searchItems?.map((item, i) => (
                  <>
                    <div className="masonry-grid-item" key={i}>
                      <PostCard
                        key={i}
                        des={item.postContent}
                        image={item.originalUrl}
                        postURL={item.url}
                        postype={item.postType}
                        data={item}
                        setData={setData}
                        isEvergreen={false}
                        isBookmark={true}
                        isDelete={false}
                      />
                    </div>
                  </>
                ))}
            </div>
          </InfiniteScroll>
        )}
        {filterItem?.length > 0 && selectedItem.length > 0 && !searchItem && (
          <InfiniteScroll
            dataLength={filterItem?.length}
            next={fetchDataSearch}
            style={{ overflow: "visible" }}
            hasMore={hasMore}
            loader={
              <div className="d-flex justify-content-center p-3">
                {isDark ? (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                  />
                ) : (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_white.svg`}
                  />
                )}
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="masonry-grid w-full -ml-2">
              {dataFilter?.length === 0 &&
                filterItem?.length > 0 &&
                filterItem?.map((item) => (
                  <>
                    <div className="masonry-grid-item" key={item.postId}>
                      <PostCard
                        key={item.postId}
                        des={item.postContent}
                        image={item.originalUrl}
                        postURL={item.url}
                        postype={item.postType}
                        data={item}
                        setData={setData}
                        isEvergreen={false}
                        isBookmark={true}
                        isDelete={false}
                      />
                    </div>
                  </>
                ))}
            </div>
          </InfiniteScroll>
        )}
        {selectedItem?.length === 0 && searchItem?.length === 0 && (
          <InfiniteScroll
            dataLength={data?.length}
            style={{ overflow: "visible" }}
            next={fetchData}
            hasMore={hasMore}
            loader={
              <div className="d-flex justify-content-center p-3">
                {isDark ? (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_dark.svg`}
                  />
                ) : (
                  <img
                    className="loading-logo"
                    src={`${IMAGE_URL}/images/short_logo_white.svg`}
                  />
                )}
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            <div className="masonry-grid w-full -ml-2">
              {dataFilter?.length === 0 &&
                filterItem?.length === 0 &&
                data?.map((item) => (
                  <>
                    <div className="masonry-grid-item" key={item.postId}>
                      <PostCard
                        key={item.postId}
                        des={item.postContent}
                        image={item.originalUrl}
                        postURL={item.url}
                        postype={item.postType}
                        data={item}
                        setData={setData}
                        isEvergreen={false}
                        isBookmark={true}
                        isDelete={false}
                      />
                    </div>
                  </>
                ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Discover;
