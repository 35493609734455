import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getApi } from "../../api/methods";
import { Link } from "react-router-dom";
import SideBar from "./sideBar/SideBar";
import CarouselPage from "./CarouselPage";
import MobileNotification from "../../components/Mobile/MobileNotification";
import './carouselPage.css'

function Carousel() {
  const { isDark } = useSelector((state) => state.common);
  const userData = useSelector((state) => state.user.userData);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isFree, setIsFree] = useState(false);
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [isRightEnd, setIsRightEnd] = useState(false);

  const ref = useRef(null);

  const scroll = (scrollOffset) => {
    if (ref.current) {
      ref.current.scrollLeft += scrollOffset;
      checkScrollLimits();
    }
  };

  const checkScrollLimits = () => {
    if (ref.current) {
      const { scrollLeft, scrollWidth, clientWidth } = ref.current;
      setIsLeftEnd(scrollLeft <= 0);
      setIsRightEnd(Math.ceil(scrollLeft + clientWidth) >= scrollWidth);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    const dataCheck = async () => {
      try {
        const transactionData = await getApi(
          `transaction/get-subscription-by-id/${userData.teamId}`
        );
        if (transactionData && transactionData.length > 0) {
          setIsFree(transactionData[0].plan_Name === "Free-Tier");
        }
      } catch (e) {
        console.log("Error fetching transaction data:", e);
      }
    };
    dataCheck();
    checkScrollLimits();

    return () => window.removeEventListener("resize", handleResize);
  }, [userData.teamId]);

  if (isMobile) {
    return <MobileNotification />;
  }

  return (
    <div className="relative w-full">
      {isFree && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
          <div
            className={`${
              isDark ? "post-ad grediant-border" : "post-ad-dark"
            }`}
            style={{width: "50%"}}
          >
            <div className="post-box-text">
              <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">
                You have discovered a premium feature. To access all our
                features, you need to subscribe to one of our plans by
                clicking the button below.
              </p>
            </div>
            <div className="btn-create-post">
              <Link
                to="/buy-subscription"
                className="grediant-button"
                style={{
                  background:
                    "linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)",
                }}
                title="Upgrade"
              >
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
      <div
        className="flex w-full"
        style={{
          filter: isFree ? "grayscale(100%)" : "none",
          pointerEvents: isFree ? "none" : "auto",
        }}
       >
        <SideBar />
        <div
          className={`${
            isDark ? "bg-white" : "bg-[#1E1E1E10]"
          } bg-opacity-50 grid grid-cols-20 z-0 grid-rows-20 bg-dots overflow-x-scroll w-full`}
          ref={ref}
          style={{ overflowX: "scroll", marginRight: 0 }}
          onScroll={checkScrollLimits}
        >
          <div 
            className={`mantine-j00bdb ${isLeftEnd ? 'scroll-disabled' : ''}`} 
            onClick={() => !isLeftEnd && scroll(-500)}
          >
            <div className={`swiper-button-prev-custom ${isLeftEnd ? 'swiper-button-disabled' : ''}`}>
              <div className="mantine-jkg5vm">
                <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-chevron-left ">
                  <path d="M15 6l-6 6l6 6"></path>
                </svg>
              </div>
            </div>
          </div>
          <div 
            className={`mantine-vur40a ${isRightEnd ? 'scroll-disabled' : ''}`} 
            onClick={() => !isRightEnd && scroll(500)}
          >
            <div className={`swiper-button-next-custom ${isRightEnd ? 'swiper-button-disabled' : ''}`}>
              <div className="mantine-13rtchx">
                <svg xmlns="http://www.w3.org/2000/svg" width="19px" height="19px" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round" className="tabler-icon tabler-icon-chevron-right ">
                  <path d="M9 6l6 6l-6 6"></path>
                </svg>
              </div>
            </div>
          </div>
          <CarouselPage />
        </div>
      </div>
    </div>
  );
}

export default Carousel;