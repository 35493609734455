import React, { useEffect, useRef, useState } from "react";
import { patchApi, postApi, getApi } from "../../api/methods";
import { useDispatch, useSelector } from "react-redux";
import { apibase } from "../../api/API";
import { isLoading } from "../../store/features/common/commonSlice";
import { toast } from "react-toastify";
import "./OrderReview.css";
import { useNavigate } from "react-router-dom";
const RAZOR_PAY_KEY_ID = import.meta.env.RAZOR_PAY_KEY_ID;

const OrderReview = () => {
  const { isDark } = useSelector((state) => state.common);
  const cartData = JSON.parse(localStorage.getItem("cartdata"));
  const user = useSelector((state) => state.user);
  // console.log({ user });
  const [showPromo, setPromo] = useState(false);
  const [totalAmount, setTotalAmount] = useState(cartData.cost);
  const [discountAmount, setDiscountAmount] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enteredPromoCode, setEnteredPromoCode] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [serialNumber, setserialNumber] = useState("");
  // State to store applied discount
  const [discount, setDiscount] = useState(0);
  
  const promoRef=useRef(null)
  // Function to handle promo code input change
  const handlePromoCodeChange = (e) => {
    setEnteredPromoCode(e.target.value);
  };

  const handleClickOutside=(e)=>{
    if(promoRef.current && !promoRef.current.contains(e.target)){
      setPromo(false)
    }
  }

  // Function to apply promo code and calculate discount
  const applyPromoCode = async () => {
    if (cartData?.planType != "Creator") {
      toast.error(
        "This coupon is applicable only for the purchase of Creator plan."
      );
      return;
    }

    try {
      const promoCodeDetails = await getApi(
        `coupon-details/get-coupon/${enteredPromoCode}`
      );
      // console.log("promoCodeDetails")
      console.log(promoCodeDetails.serialNumber);
      // setAppliedCoupon()
      setserialNumber(promoCodeDetails.serialNumber);
      // console.log("promoCodeDetails")

      if (promoCodeDetails.discountType === "value") {
        setDiscountAmount(promoCodeDetails.discountValue);
        setTotalAmount(
          Number(cartData.cost) - Number(promoCodeDetails.discountValue)
        );
      } else if (promoCodeDetails.discountType === "percentage") {
        console.log(cartData.cost);
        console.log(
          (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) / 100
        );
        setDiscountAmount(
          (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) / 100
        );
        setTotalAmount(
          Number(cartData.cost) -
            Number(
              (Number(cartData.cost) * Number(promoCodeDetails.discountValue)) /
                100
            )
        );
        // cartData?.planDuration === 'Yearly' ? cartData.cost * 12 : cartData.cost
      }
      if (cartData.planDuration === "Yearly") {
        setDiscountAmount(discountAmount * 12);
      }

      setAppliedCoupon(enteredPromoCode);
      toast.success("Coupon Code has been Applied");
    } catch (error) {
      console.log(error);
      setEnteredPromoCode("");
      if (error.response && error.response.status === 404) {
        toast.error(error.response.data.message);
      }
      // Check if the error response has a status code of 400
      else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred while applying the coupon.");
      }
      // toast.error('Invalid promo code!');
    }
  };

  // Calculate total amount after discount
  const calculateTotalAmount = () => {
    // Calculate total amount without discount
    let totalAmount =
      cartData?.planDuration === "Yearly"
        ? cartData?.cost * 12
        : cartData?.cost;
    // Apply discount
    totalAmount -= (totalAmount * discount) / 100;
    return totalAmount.toFixed(2); // Round to two decimal places
  };

  const [updButton, setUpdButton] = useState(false);

  const [billingData, setBillingData] = useState({
    pincode: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    email: "",
    name: "",
    address: "",
    UserId: "",
  });
  const [billingUpdData, setBillingUpdData] = useState({
    pincode: "",
    city: "",
    state: "",
    country: "",
    phone: "",
    email: "",
    name: "",
    address: "",
    UserId: "",
  });
  // console.log({ billingUpdData });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBillingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // fetch any user billing information
  const getUserBillData = () => {
    getApi(`billing/get-user-billing-data/${user?.userData?.linkedinId}`)
      .then((res) => {
        setBillingUpdData(res.data);
        setBillingData(res.data);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  // update any user billing information
  const updateBillingData = () => {
    dispatch(isLoading(true));
    patchApi(
      `billing/update-billing-information/${user?.userData?.linkedinId}`,
      billingData
    )
      .then((res) => {
        dispatch(isLoading(false));
        // console.log(res);
        toast.success("Address updated successfully!");
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.warn(err);
        toast.error("Update failed!");
      });
  };

  // create any user billing information
  const handleBillingData = () => {
    dispatch(isLoading(true));
    postApi("billing/create-billing-information", {
      UserId: user?.userData?.linkedinId,
      address: billingData?.address,
      pincode: billingData?.pincode,
      city: billingData?.city,
      state: billingData?.state,
      country: billingData?.country,
      phone: billingData?.phone,
      email: billingData?.email,
      name: billingData?.name,
    })
      .then((res) => {
        dispatch(isLoading(false));
        // console.log(res);
        toast.success("Address saved successfully!");
      })
      .catch((err) => {
        dispatch(isLoading(false));
        console.warn(err);
        toast.error("Something went wrong!");
      });
  };
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // Function to calculate the end date based on the plan duration
    const calculateEndDate = () => {
      // Create a new Date object for the current date
      let newEndDate = new Date();

      // Calculate the new end date based on the plan duration
      if (cartData?.planDuration === "Monthly") {
        newEndDate.setMonth(newEndDate.getMonth() + 1);
      } else if (cartData?.planDuration === "Yearly") {
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
      }

      // Extract day, month, and year from the new end date
      let day = newEndDate.getDate();
      let month = newEndDate.getMonth() + 1; // Months are zero-indexed
      let year = newEndDate.getFullYear();

      // Format the end date as "day-month-year"
      let formattedEndDate = `${day}-${month}-${year}`;

      // Update the state variable with the formatted end date
      setEndDate(formattedEndDate);
    };

    // Call the calculateEndDate function when the cartData.durationType changes
    calculateEndDate();
  }, [cartData?.durationType]);

  let currentDate = new Date();
  let day = currentDate.getDate();
  let month = currentDate.getMonth() + 1; // Months are zero-indexed
  let year = currentDate.getFullYear();
  let formattedDate = `${day}-${month}-${year}`;

  const handleTransaction = (subscriptionId) => {
    // Check if the user has a teamId
    if (user?.userData?.teamId) {
      // Calculate the payment amount based on the plan duration
      let pay_amnt = cartData?.planDuration === 'Yearly' ? cartData?.cost * 12 : cartData?.cost;

      // Send a POST request to create transaction data
      postApi('transaction/create-cart-data', {
        userId: user?.userData?.linkedinId,
        plan_Name: cartData.planType,
        pay_amnt: pay_amnt,
        plan_type: cartData?.planDuration,
        credits: cartData.totalCredits,
        No_Ac: cartData.accounts,
        teamId: user?.userData?.teamId,
        planStatus: true,
        endDate: endDate,
        startDate: formattedDate,
        appliedCoupon: serialNumber,
        subscriptionId: subscriptionId
      }).then((res) => {
        // Handle successful response
        // updateCreditHistory(res.data)
        // console.log(res);
      }).catch((err) => {
        // Handle error
        console.warn(err);
      });
    }
  };

  // console.log('linked',user?.userData?.linkedinId);

  const updateCreditHistory = async (creditInfo) => {
    try {
      const bodyPost = {
        teamId: user?.userData?.teamId,
        name: cartData.planType,
        subscriptionId: creditInfo.subscriptionId,
        userId: user?.userData?.linkedinId,
        crpoint: `${cartData.totalCredits}`,
      };

      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  // update user subscription plan
  const updateUserSubsscription = () => {
    patchApi(`user/user-session`, {
      linkedinId: user?.userData?.linkedinId,
      subscription: true,
    })
      .then((res) => {
        // console.log(res);
        // alert('fgdfgdfgfdg',JSON.stringify(res));
      })
      .catch((err) => {
        console.warn(err);
        // console.log('fgdfgdfgfdg',err);
      });
  };

  // allow team members subscription plan activate
  const allowTeamSubcription = async () => {
    try {
      const bodyPost = {
        teamId: user?.userData?.teamId,
        subscription: true,
      };

      await patchApi("user/team-subscribed", bodyPost);
      toast.success("Plan Activated!");
      navigate("/credit-history");
      // window.location.href = `${IMAGE_URL}/credit-history`
    } catch (error) {
      console.warn("update team subscription:", error);
      toast.error("Something went wrong!");
    }
  };

  const subscriptionId = localStorage.getItem("subscriptionId");
  // const handleUpdateUserSub = async () => {
  //   await patchApi('user/user-session', { linkedinId: user.userData?.linkedinId, subscription: false })
  // }

  // cancel subscription of all team members
  const handleCancelSubscription = async () => {
      if (subscriptionId) {
        await patchApi('transaction/update-subscription', { subscriptionId:subscriptionId })
          .then((res) => {
            // console.log(res);
            // handleUpdateUserSub()
          })
          .catch((err) => {
            console.warn(err);
          })
      }
   

  }
  // Live Mode 
//   const planMapping = {
//   'Growero Growth Annual': 'plan_PIKwLOxecG9ZOQ',
//   'Growero Creator Annual': 'plan_PIKvU2OCX2hSAi',
//   'Growero Pro Annual': 'plan_PIKuiLlnoOhNUI',
//   'Growero Growth Monthly': 'plan_PIKspBslAL1ymY',
//   'Growero Creator Monthly': 'plan_PIKsKQSB5HGdKU',
//   'Growero Pro Monthly': 'plan_PIKrgKUV7j4NHy'
// };

// Test Mode
const planMapping = {
  'Growth_Yearly': 'plan_PILwor6dsNJC4I',  // Growero Growth Annual
  'Creator_Yearly': 'plan_PILwZuoGBKhSEJ', // Growero Creator Annual
  'Pro_Yearly': 'plan_PILvxcMIzb6iCg',     // Growero Pro Annual
  'Pro_Monthly': 'plan_PILxdzKU3d7GZf',    // Growero Pro Monthly
  'Creator_Monthly': 'plan_PILxQx6sfD9EoQ',// Growero Creator Monthly
  'Growth_Monthly': 'plan_PILxFuezzhW8hZ'  // Growero Growth Monthly
};



const handlePayment = async () => {
  // Construct key using planType and planDuration
  const selectedPlanKey = `${cartData?.planType}_${cartData?.planDuration}`;
  const planId = planMapping[selectedPlanKey]; // Use the constructed key to find planId
  
  if (!planId) {
    console.error('Invalid or no plan selected.');
    return;
  }

  const totalCount = cartData?.planDuration === 'Monthly' ? 12 : 1;

  try {
    const subscription = await postApi('payment/create-subscription', {
      plan_id: planId,
      customer_notify: 1,
      total_count: totalCount
    });

    const options = {
      key: "rzp_test_Alla3u3aErjJQj", // Your Razorpay Key ID
      subscription_id: subscription.id,
      name: 'Growero',
      description: `${cartData.planType} ${cartData.planDuration} Subscription`,
      handler: function(response) {
        handleSuccessfulPayment(response);
      },
      prefill: {
        name: 'Customer Name',
        email: billingData?.email,
        contact: billingData?.phone
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
    
  } catch (error) {
    console.error("Error initiating payment:", error);
  }
};




  const handleSuccessfulPayment = async (paymentResponse) => {
    if (paymentResponse) {
      try {
          const response = await postApi('payment/verify-payment', {
          razorpay_subscription_id: paymentResponse.razorpay_subscription_id,
          razorpay_payment_id: paymentResponse.razorpay_payment_id,
          razorpay_signature: paymentResponse.razorpay_signature,
          transactionId: "1234", // Make sure this exists in your TransactionData table
          userId: user?.userData?.linkedinId,
          amount: 100, // as a number
          currency: "USD",
        });
        console.log("Payment verification response:", response);
      } catch (error) {
        console.error(
          "Payment verification error:",
          error.response?.data || error.message
        );
      }
    handleCancelSubscription();
    updateUserSubsscription();
    allowTeamSubcription();
    handleTransaction(paymentResponse.razorpay_subscription_id);
    localStorage.removeItem('subscriptionId');
      } else {
       toast.error('Payment Cancelled');
      }

    // console.log('Payment successful, proceeding with app logic');
  };

  // buy subscription plan by user
  const checkout = async () => {
    if (totalAmount == 0) {
      try {
        const response = await patchApi(
          `coupon-details/mark-coupon/${appliedCoupon}`,
          { isUsed: true }
        );
        // Handle successful marking of coupon
        console.log("Coupon marked as used:", response);
      } catch (err) {
        // Handle error in marking coupon
        console.warn("Error marking coupon as used:", err);
      }

      handleCancelSubscription();
      updateUserSubsscription();
      allowTeamSubcription();
      localStorage.removeItem("subscriptionId");
      handleTransaction();
      return;
    }
    handlePayment();
  };

  useEffect(() => {
    getUserBillData();
  }, []);

  useEffect(()=>{
    document.addEventListener("mousedown",handleClickOutside)

    return ()=>{
      document.removeEventListener("mousedown",handleClickOutside)
    }
  },[])
  return (
    <div className={`min-h-screen py-12 px-4 sm:px-6 lg:px-8`}>
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Billing Information Section */}
          <div className="space-y-6">
            <h2 className="text-2xl font-bold">Billing Information</h2>
            <div className={`rounded-lg shadow-lg p-6 bg-white`}>
              {/* Name Input */}
              <div className="mb-4">
                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500`}>
                    <i className="fa fa-user"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="Full Name"
                    name="name"
                    value={billingData?.name || user.userData?.userName}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Email Input */}
              <div className="mb-4">
                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="Email"
                    name="email"
                    value={billingData?.email || user.userData?.email}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Phone Input */}
              <div className="mb-4">
                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-phone"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="Phone"
                    name="phone"
                    value={billingData?.phone}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Address Fields Grid */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-globe"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="Country"
                    name="country"
                    value={billingData?.country}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-map"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="State"
                    name="state"
                    value={billingData?.state}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-location-arrow"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="City"
                    name="city"
                    value={billingData?.city}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="relative">
                  <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${isDark ? 'text-gray-400' : 'text-gray-500'}`}>
                    <i className="fa fa-sort-numeric-desc"></i>
                  </span>
                  <input
                    type="text"
                    className={`w-full pl-10 pr-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                    placeholder="Pin Code"
                    name="pincode"
                    value={billingData?.pincode}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              {/* Action Buttons */}
              {billingUpdData?.name ? (
                updButton === false ? (
                  <button
                    onClick={() => setUpdButton(true)}
                    className="w-full py-2 px-4 rounded-lg bg-yellow-500 hover:bg-yellow-600 text-white font-medium transition-colors"
                  >
                    Edit
                  </button>
                ) : (
                  <div className="grid grid-cols-2 gap-4">
                    <button
                      onClick={updateBillingData}
                      className="py-2 px-4 rounded-lg bg-blue-500 hover:bg-blue-600 text-white font-medium transition-colors"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => setUpdButton(!updButton)}
                      className="py-2 px-4 rounded-lg border border-red-500 text-red-500 hover:bg-red-500 hover:text-white font-medium transition-colors"
                    >
                      Cancel
                    </button>
                  </div>
                )
              ) : (
                <button
                  onClick={handleBillingData}
                  className="w-full py-2 px-4 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-medium transition-colors"
                >
                  Save
                </button>
              )}
            </div>
          </div>

          {/* Order Summary Section */}
          <div className="space-y-6">
            <h2 className="text-2xl font-bold">Order Summary</h2>
            <div className={`rounded-lg shadow-lg bg-white`}>
              <div className="p-6">
                <table className="w-full">
                  <tbody className="divide-y divide-gray-200">
                    <tr className="py-3">
                      <td className="py-3">Plan Name</td>
                      <td className="py-3 font-semibold">{cartData?.planType}</td>
                    </tr>
                    <tr>
                      <td className="py-3">No. of Accounts</td>
                      <td className="py-3">{cartData?.accounts}</td>
                    </tr>
                    <tr>
                      <td className="py-3">Credits</td>
                      <td className="py-3">{cartData?.totalCredits}</td>
                    </tr>
                    <tr>
                      <td className="py-3">Plan Duration</td>
                      <td className="py-3">{cartData?.planDuration}</td>
                    </tr>
                    <tr>
                      <td className="py-3">Sub Total</td>
                      <td className="py-3">
                        {cartData?.currencyType}{cartData?.planDuration === 'Yearly' ? cartData.cost * 12 : cartData.cost}
                      </td>
                    </tr>
                    {discountAmount && (
                      <tr>
                        <td className="py-3">Discount</td>
                        <td className="py-3 text-green-500">
                          -{cartData?.currencyType}{discountAmount}
                        </td>
                      </tr>
                    )}
                    <tr className="font-bold">
                      <td className="py-3">Total</td>
                      <td className="py-3">
                        {cartData?.currencyType}
                        {cartData?.planDuration === 'Yearly' ? totalAmount * 12 : totalAmount}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Promo Code Section */}
                <div className="mt-6">
                  {showPromo ? (
                    <div ref={promoRef} className="space-y-2">
                      <div className="flex space-x-2">
                        <input
                          type="text"
                          className={`flex-1 px-4 py-2 rounded-lg border bg-white border-gray-300 focus:ring-2 focus:ring-purple-500 focus:border-transparent`}
                          placeholder="Enter Promo Code"
                          value={enteredPromoCode}
                          onChange={handlePromoCodeChange}
                        />
                        <button
                          onClick={applyPromoCode}
                          className="px-6 py-2 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-medium transition-colors"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={() => setPromo(!showPromo)}
                      className={`text-base font-medium ${isDark ? 'text-purple-400' : 'text-purple-600'} hover:underline`}
                    >
                      Have a promo code?
                    </button>
                  )}
                </div>

                {/* Checkout Button */}
                <button
                  onClick={checkout}
                  className="w-full mt-6 py-3 px-6 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white font-medium transition-colors"
                >
                  Proceed to Payment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderReview;
