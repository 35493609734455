import React, { useEffect, useState } from 'react'
import './PostIdea.css'
import AIPostGen from '../../components/PostIdea/AIPostGen/AIPostGen'
import PreviewPost from '../../components/PostIdea/PreviewPost/PreviewPost'
import CreatePost from '../../components/PostIdea/CreatePost/CreatePost'
import { changeLayoutShift } from "../../store/features/common/commonSlice";
import { useSelector, useDispatch } from 'react-redux'
import { getApi } from '../../api/methods'

const AIContent = () => {
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const user = useSelector(state=>state.user)
  const [getTwitterUser, setTwitterUser]=useState(null)
  // useEffect(()=>{
  //   dispatch(changeLayoutShift(false));
  //   },[])
  // fetch twitter data from user linkedin id
  const getTwitterbyId= async ()=>{
    getApi(`twitter/get-twitter-user/${user?.userData?.linkedinId}`)
    .then((res)=>{
      setTwitterUser(res.data)
    })
  }

  useEffect(()=>{
    getTwitterbyId()
    // console.log({getTwitterUser});
  },[])
  return (
    <>
        <div className={`w-[100%]  max-md:pr-[30px] max-md:pl-[30px] p-4`}>
            <div className="flex w-[100%] gap-6 tst">
                <div className={`lg:w-[25%] pb-2`}>
                    <AIPostGen/>
                </div>
                <div className='removedis border-r-[1px] border-gray-600 min-h-[80vh]'></div>
                <div className={`lg:w-[35%] pb-2`}>
                    <PreviewPost />
                </div>
                <div className='removedis border-r-[1px] border-gray-600 min-h-[80vh] '></div>
                <div className={`lg:w-[30%] pb-2 overflow-x-hidden`}>
                    <CreatePost setTwitterUser={setTwitterUser} getTwitterUser={getTwitterUser} />
                </div>
            </div>
        </div>
    </>
  )
}

export default AIContent