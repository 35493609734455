// replace '&' text to html code for discover post displaying not proper format
const convertCodeToText = (textHTML) => {
  // Ensure textHTML is a string before calling replace
  if (typeof textHTML !== 'string') {
    console.warn('Expected a string but received:', textHTML);
    return ''; // Return an empty string or handle it as needed
  }

  // Proceed with the replace operation
  return textHTML.replace(/&amp;/g, '&'); // Example: converting &amp; to &
};

export default convertCodeToText;
