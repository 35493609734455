import { React, useState, useEffect, useRef } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import "./aiBar.css";
import { Input } from "../../../components/ui/input";
import { Textarea } from "../../../components/ui/textarea";
import aiImg from "../../../../src/assets/AIwhite.svg";
import { Button } from "../../../components/ui/button";
import { useSelector, useDispatch } from "react-redux";
import {
  aiPostTopic,
  aiPostArticle,
  aiPostText,
  resetSlides,
} from "../../../store/features/carousel/action/adjustAction";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import axios from "axios";
import { axiosInstance, BASE_URL } from "../../../api/API";
import { getApi, postApi } from "../../../api/methods";
import Modal from "react-bootstrap/esm/Modal";
import addImg from "../../../assets/addImg.svg";
import swipeArr from "../../../assets/swipeArr.svg";
import bookmarkImg from "../../../assets/bookmark.svg";
import rightArr from "../../../assets/rightArr.svg";
import circle from "../../../assets/circle.svg";
import thunder from "../../../assets/thunder.svg";
import { Form } from "react-bootstrap";
import {
  adjustDesc,
  adjustImage,
  adjustSubtitle,
  adjustTitle,
  addSlideData,
  deleteSlideData,
  backgroundImageSet,
  backgroundImageOpacitySet,
  backgroundImageSizeSet,
  backgroundImagePositionSet,
  backgroundImageRemove,
  imageSet,
  duplicateSlideData,
  imageSizeSet,
  imagePositionSet,
  backgroundImageAllSet,
  revertbackgroundImageSet,
  backgroundAllSet,
  changeSubtitle,
  changeTitle,
  changeDesc,
} from "../../../store/features/carousel/action/adjustAction";
import { IMAGE_URL, IMAGE_URL_BACKEND, apibase } from "../../../api/API";
import { userProfileData } from "../../../store/features/analytics/analyticsAction";

import Popup from "./Popup"; // Adjust path as necessary

// import html2canvas from 'html2canvas';
import { isLoading } from "../../../store/features/common/commonSlice";
// import ContentOverlay from "../../../components/ContentOverlay/ContentOverlay";

import EditPostOverlay from './EditPostOverlay';

const PatternContainer = ({
  pattern,
  bgOpacity,
  color = "rgb(128, 128, 128)",
}) => {
  const baseOpacity = Math.min(bgOpacity * 1.5, 1);

  const svgPatterns = {
    grid: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="grid"
            width="60"
            height="60"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M 60 0 L 0 0 0 60"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="0.8"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#grid)" />
      </svg>
    ),
    dots: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="dots"
            width="25"
            height="25"
            patternUnits="userSpaceOnUse"
          >
            <circle
              cx="12.5"
              cy="12.5"
              r="2"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#dots)" />
      </svg>
    ),
    stripped: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="stripes"
            width="40"
            height="40"
            patternUnits="userSpaceOnUse"
          >
            <rect
              width="1"
              height="40"
              x="0"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
            <rect
              width="1"
              height="40"
              x="3"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
            <rect
              width="1"
              height="40"
              x="6"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
            <rect
              width="1"
              height="40"
              x="20"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
            <rect
              width="1"
              height="40"
              x="23"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
            <rect
              width="1"
              height="40"
              x="26"
              fill={`${color}`}
              fillOpacity={baseOpacity}
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#stripes)" />
      </svg>
    ),
    diagonals: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="diagonals"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
            patternTransform="rotate(45)"
          >
            <line
              x1="0"
              y1="0"
              x2="0"
              y2="20"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="3"
            />
            <line
              x1="10"
              y1="0"
              x2="10"
              y2="20"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.5}
              strokeWidth="1.5"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#diagonals)" />
      </svg>
    ),
    cage: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="cage"
            width="100"
            height="100"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="1.5"
            />
            <path
              d="M25 50 A 25 25 0 0 1 50 25 A 25 25 0 0 1 75 50 A 25 25 0 0 1 50 75 A 25 25 0 0 1 25 50"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.3}
              strokeWidth="0.8"
              transform="rotate(45, 50, 50)"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#cage)" />
      </svg>
    ),
    boxes: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="boxes"
            width="60"
            height="60"
            patternUnits="userSpaceOnUse"
          >
            <rect
              width="30"
              height="30"
              x="0"
              y="0"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="1.5"
            />
            <rect
              width="30"
              height="30"
              x="30"
              y="30"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="1.5"
            />
            <line
              x1="0"
              y1="0"
              x2="30"
              y2="30"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.7}
              strokeWidth="1"
            />
            <line
              x1="30"
              y1="0"
              x2="0"
              y2="30"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.7}
              strokeWidth="1"
            />
            <line
              x1="30"
              y1="30"
              x2="60"
              y2="60"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.7}
              strokeWidth="1"
            />
            <line
              x1="60"
              y1="30"
              x2="30"
              y2="60"
              stroke={`${color}`}
              strokeOpacity={baseOpacity * 0.7}
              strokeWidth="1"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#boxes)" />
      </svg>
    ),
    chevron: (
      <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern
            id="chevron"
            width="60"
            height="30"
            patternUnits="userSpaceOnUse"
          >
            <path
              d="M0 30 L30 0 L60 30"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="2"
            />
            <path
              d="M0 30 L30 60 L60 30"
              fill="none"
              stroke={`${color}`}
              strokeOpacity={baseOpacity}
              strokeWidth="2"
            />
          </pattern>
        </defs>
        <rect width="100%" height="100%" fill="url(#chevron)" />
      </svg>
    ),
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 0,
        overflow: "hidden",
      }}
    >
      {svgPatterns[pattern]}
    </div>
  );
};

function AiBar() {
  const state = useSelector((state) => state);
  const { isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);

  const common = useSelector((state) => state.common);
  const [active, setActive] = useState("Topic");
  const [isLoadingLocal, setisLoading] = useState(false);
  const [topic, setTopic] = useState("");
  const [text, setText] = useState("");
  const [tone, setTone] = useState("Creative");
  const [articleLink, setArticleLink] = useState("");
  const [targetAudience, setTargetAudience] = useState("Beauty Enthusiasts");
  const [slides, setSlides] = useState("3");
  const [toneError, setToneError] = useState(false);
  const [slidesError, setSlidesError] = useState(false);
  const [topicLimitExceeded, setTopicLimitExceeded] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [schedulePanelOpen, setSchedulePanelOpen] = useState(false);
  const [scheduleDate, setScheduleDate] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [checkCustomTime, setCheckCustomTime] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const linkedinAccessToken = user.userData.accessToken;
  const upper = useSelector((s) => s.adjustReducer);
  const textChange = useSelector((s) => s.textReducer);
  const canvaChange = useSelector((s) => s.canvaReducer);
  const [verticalAlignment, setVerticalAlignment] = useState("");
  const [textAlignment, setTextAlignment] = useState("");
  const [h, setH] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [pattern, setPattern] = useState("");
  const [titleFont, setTitleFont] = useState("");
  const [bodyFont, setBodyFont] = useState("");
  const [subtitleSize, setSubtitleSize] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const [descSize, setDescSize] = useState("");
  const [layout, setLayout] = useState("");
  const [slideNo, setSlideNo] = useState("");
  const [slideStyle, setSlideStyle] = useState("");
  const [roundVal, setRoundVal] = useState("");
  const [isSwipe, setIsSwipe] = useState("");
  const [isBookmark, setIsBookmark] = useState("");
  const [swipeText, setSwipeText] = useState("");
  const [swipeIcon, setSwipeIcon] = useState("");
  const [headShot, setHeadShot] = useState("");
  const [introOutro, setIntroOutro] = useState("");
  const [imgSize, setImgSize] = useState("");
  const [nameSize, setNameSize] = useState("");
  const [handleSize, setHandleSize] = useState("");
  const [headShotColor, setHeadShotColor] = useState("");
  const [bgOpacity, setBgOpacity] = useState("");
  const [titleColor, setTitleColor] = useState("");
  const [descColor, setDescColor] = useState("");
  const [subTitleColor, setSubTitleColor] = useState("");
  const [pageNoColor, setPageNoColor] = useState("");
  const [swipeColor, setSwipeColor] = useState("");
  const [bookmarkColor, setBookmarkColor] = useState("");
  const [boxColor, setBoxColor] = useState("");
  const [aspectRatio, setAspectRatio] = useState("");
  const [search, setSearch] = useState("");
  const [aiAssistOpen, setAiAssistOpen] = useState(false); // State for AI Assist popup
  const [AiTextValue, setAiTextValue] = useState("");
  const [apiResponse, setApiResponse] = useState(null);
  const [fileName, setFileName] = useState("");
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const [currentSlide, setCurrentSlide] = useState(0);
  const [description, setDescription] = useState("");

  const nextSlide = () => {
    if (currentSlide < upper.slides.length - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };

  const handleAiTextValueChange = (e) => {
    setAiTextValue(e.target.value);
  };

  const scrollContainerRef = useRef(null);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };
  const [openContentOverlay, setOpenContentOverlay] = useState(false); // Define the state for content overlay

  const handleGenerate = () => {
    // Replace 'YOUR_API_ENDPOINT' with the actual endpoint you want to call
    console.log(AiTextValue);
    axiosInstance
      .post("ai-post/generate-description-from-topic", { topic: AiTextValue })
      .then((response) => {
        setDescription(response.data.description);
        console.log("API Response:", response.data);
      })
      .catch((error) => {
        console.error("Error making API request:", error);
      });
    setAiAssistOpen(false);
  };

  // const { error, isLoading } = useSelector((state) => state.saveCarouselsReducer);
  const linkedinId = user?.userData?.linkedinId;
  // const [imageUrls, setImageUrls] = useState([]);
  // const [isPosting, setIsPosting] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    dispatch(userProfileData(userData.linkedinId));
  }, []);
  useEffect(() => {
    setTitleFont(textChange.titleFontType || "Poppins");
    setBodyFont(textChange.bodyFontType || "Poppins");
    setSubtitleSize(textChange.fontSizeSubtitle);
    setTitleSize(textChange.fontSizeTitle);
    setDescSize(textChange.fontSizeDesc);
    setTextAlignment(textChange.textAlign || "center");
    setVerticalAlignment(textChange.verticalAlign || "center");
    setTitleColor(textChange.titleColor);
    setDescColor(textChange.descColor);
    setSubTitleColor(textChange.subTitleColor);
    setPageNoColor(textChange.pageNoColor);
  }, [textChange]);

  const handleToneChange = (tone) => {
    setTone(tone);
  };

  const autoScheduleAndPost = async () => {
    try {
      // Step 1: Get auto-scheduler slots
      const userTimeSlots = await axios.get(
        `${BASE_URL}schedule/get-auto-scheduler-slot/${user?.userData?.linkedinId}`
      );
      console.log("userTimeSlots:", userTimeSlots);

      let utcTimeSlot = [];
      const currentDate = new Date().toISOString().split("T")[0];

      for (const slot of userTimeSlots.data.timeSlot) {
        const futureDate = new Date(`${currentDate}T${slot}`).toISOString();
        utcTimeSlot.push(futureDate?.split(".")[0]?.split("T")[1]);
      }

      // Step 2: Generate and upload PDF
      toast.info("Preparing carousel for LinkedIn...");
      const pdf = await generatePDF();
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await postApi(
        "linkedin/document-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploadResponse:", uploadResponse);

      const asset = uploadResponse.asset;
      console.log("asset:", asset);

      // Step 3: Prepare post data
      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary:
          state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Step 4: Schedule the post
      const bodyPost = {
        ...postData,
        utcTimeSlots: utcTimeSlot,
        userId: user?.userData?.linkedinId,
        originalUrl: null, // Add other necessary fields based on your backend schema
        media: asset, // Asset ID from the upload response
        mediaTitle: "Carousel", // Title for the media
        mediaDis: "application/pdf", // Media type or description
        postType: "Carousel", // Type of the post
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null, // Post ID if required
        postTag: null, // Post tags if any
        autoCommentId: null, // Auto comment ID if any
        platformPost: "LinkedIn",
      };

      console.log("bodyPost for scheduling:", bodyPost);

      toast.info("Scheduling LinkedIn post...");
      const scheduleResponse = await postApi(
        "schedule/create-auto-schedule-post",
        bodyPost,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
          },
        }
      );
      console.log("scheduleResponse:", scheduleResponse);

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success(
        "Carousel PDF scheduled for posting on LinkedIn successfully!"
      );
      dispatch(resetSlides());
      return scheduleResponse;
    } catch (error) {
      console.error("Error in auto-scheduling and posting:", error);
      toast.error(`Failed to auto-schedule and post: ${error.message}`);
      throw error;
    }
  };

  const schedulePostHander = () => {
    // setSchedulePanelOpen(true)
    // dispatch(changePostSelection('schedule-post'))

    // if (post.postContent) {
    setSchedulePanelOpen(true);
    // dispatch(changePostSelection("schedule-post"));
  };

  const cancelSchedulePost = () => {
    setSchedulePanelOpen(false);
    setScheduleDate("");
    setScheduleTime("");
    setCheckCustomTime(false);
  };
  const onScheduleConfirmHandler = async () => {
    // Close the modal first
    setSchedulePanelOpen(false);

    const futureDate = new Date(`${scheduleDate}T${scheduleTime}`);

    try {
      // Step 1: Generate PDF after the modal is closed
      // toast.info("Preparing carousel for LinkedIn...");
      const pdf = await generatePDF();
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      const pdfBlob = pdf.output("blob");
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", user?.userData?.linkedinId);

      // Step 2: Upload PDF to LinkedIn
      // toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await postApi(
        "linkedin/document-upload",
        formData,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("uploadResponse:", uploadResponse);

      const asset = uploadResponse.asset;
      console.log("asset:", asset);

      // Step 3: Prepare post data
      const postData = {
        author: `urn:li:person:${user?.userData?.linkedinId}`,
        commentary:
          state.adjustReducer.slides[0].title || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Step 4: Schedule the post
      const bodyPost = {
        ...postData,
        date_time: futureDate.toISOString().split(".")[0],
        userId: user?.userData?.linkedinId,
        originalUrl: null, // Add other necessary fields based on your backend schema
        media: asset, // Asset ID from the upload response
        mediaTitle: "Carousel", // Title for the media
        mediaDis: "application/pdf", // Media type or description
        postType: "Carousel", // Type of the post
        connectionType: "LinkedIn",
        postContent: postData.commentary,
        postId: null, // Post ID if required
        postTag: null, // Post tags if any
        autoCommentId: null, // Auto comment ID if any
        platformPost: "LinkedIn",
      };

      // toast.info("Scheduling LinkedIn post...");
      const scheduleResponse = await postApi(
        "schedule/create-schedule-post",
        bodyPost,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
          },
        }
      );

      if (!scheduleResponse.success) {
        throw new Error("Failed to schedule LinkedIn post");
      }

      toast.success(
        "Carousel PDF scheduled for posting on LinkedIn successfully!"
      );
      dispatch(resetSlides());
      setisLoading(false);
    } catch (error) {
      console.error("Error in scheduling carousel post:", error);
      toast.error(`Failed to schedule carousel post: ${error.message}`);
    }
  };

  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);
  const useOutsideClick = (ref, callback) => {
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [ref, callback]);
  };

  const image = "/images/pp.jpeg";

  const [openAdjustDropdownIndex, setOpenAdjustDropdownIndex] = useState(null);
  useOutsideClick(dropdownRef, () => {
    setOpenAdjustDropdownIndex(null);
  });

  const [openBackgroundDropdownIndex, setOpenBackgroundDropdownIndex] =
    useState(null);
  useOutsideClick(dropdownRef2, () => {
    setOpenBackgroundDropdownIndex(null);
  });

  const handleToggleAdjustDropdown = (index) => {
    setOpenAdjustDropdownIndex(
      openAdjustDropdownIndex === index ? null : index
    );
  };
  const handleToggleBackgroundDropdown = (index) => {
    setOpenBackgroundDropdownIndex(
      openBackgroundDropdownIndex === index ? null : index
    );
  };
  console.log({ subtitleSize });
  const handleSubtitleAdjust = (index, setSubtitle) => {
    const data = {
      index: index,
      subtitle: setSubtitle,
    };
    dispatch(adjustSubtitle(data));
  };

  const handleTitleAdjust = (index, setTitle) => {
    const data = {
      index: index,
      title: setTitle,
    };
    dispatch(adjustTitle(data));
  };
  const handleDescAdjust = (index, setDesc) => {
    const data = {
      index: index,
      desc: setDesc,
    };
    dispatch(adjustDesc(data));
  };
  const handleImageAdjust = (index, setImage) => {
    const data = {
      index: index,
      image: setImage,
    };
    dispatch(adjustImage(data));
  };

  const settingsChange = useSelector((s) => s.settingsReducer);
  const headShotChange = useSelector((s) => s.headShotReducer);
  const imageUploadChange = useSelector((s) => s.imageUploadReducer);
  console.log(imageUploadChange.images);

  useEffect(() => {
    if (aspectRatio) {
      const [width, height] = aspectRatio.split(":").map(Number);
      setH((520 * height) / width);
    }
  }, [aspectRatio]);

  useEffect(() => {
    setTitleFont(textChange.titleFontType || "Poppins");
    setBodyFont(textChange.bodyFontType || "Poppins");
    setSubtitleSize(textChange.fontSizeSubtitle);
    setTitleSize(textChange.fontSizeTitle);
    setDescSize(textChange.fontSizeDesc);
    setTextAlignment(textChange.textAlign || "center");
    setVerticalAlignment(textChange.verticalAlign || "center");
    setTitleColor(textChange.titleColor);
    setDescColor(textChange.descColor);
    setSubTitleColor(textChange.subTitleColor);
    setPageNoColor(textChange.pageNoColor);
  }, [textChange]);

  useEffect(() => {
    setLayout(canvaChange.layout);
    setPattern(canvaChange.pattern);
    setBgOpacity(canvaChange.bgOpacity);
    setBgColor(canvaChange.bgColor);
    setAspectRatio(canvaChange.aspectRatio);
    setBoxColor(canvaChange.boxDivColor);
  }, [canvaChange]);

  useEffect(() => {
    setSlideNo(settingsChange.slideNo);
    setSlideStyle(settingsChange.slideNoStyle);
    setRoundVal(settingsChange.round);
    setIsSwipe(settingsChange.swipe);
    setIsBookmark(settingsChange.bookmark);
    setSwipeText(settingsChange.swipeText);
    setSwipeIcon(settingsChange.swipeIcon);
    setSwipeColor(settingsChange.swipeColor);
    setBookmarkColor(settingsChange.bookmarkColor);
  }, [settingsChange]);

  useEffect(() => {
    setHeadShot(headShotChange.headShot);
    setIntroOutro(headShotChange.introOutro);
    setImgSize(headShotChange.imgSize);
    setNameSize(headShotChange.nameSize);
    setHandleSize(headShotChange.handleSize);
    setHeadShotColor(headShotChange.headShotColor);
    console.log(headShotChange.headShotColor);
  }, [headShotChange]);

  const postToLinkedIn = async () => {
    setIsPosting(true);
    toast.info("Preparing carousel for LinkedIn...");

    try {
      // Generate PDF
      const pdf = await generatePDF();
      if (!pdf) {
        throw new Error("Failed to generate PDF");
      }

      // Convert PDF to Blob
      const pdfBlob = pdf.output("blob");

      // Create FormData and append PDF
      const formData = new FormData();
      formData.append("file", pdfBlob, "carousel.pdf");
      formData.append("userId", linkedinId);

      // Upload PDF to LinkedIn
      toast.info("Uploading PDF to LinkedIn...");
      const uploadResponse = await axios.post(
        `${BASE_URL}linkedin/document-upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const asset = uploadResponse.data.asset;
      
      // Prepare post data
      const postData = {
        author: `urn:li:person:${linkedinId}`,
        commentary: description || "Check out my new carousel!",
        visibility: "PUBLIC",
        distribution: {
          feedDistribution: "MAIN_FEED",
          targetEntities: [],
          thirdPartyDistributionChannels: [],
        },
        content: {
          media: {
            title: "Carousel PDF",
            id: asset,
          },
        },
        lifecycleState: "PUBLISHED",
        isReshareDisabledByAuthor: false,
      };

      // Create LinkedIn post
      toast.info("Creating LinkedIn post...");
      const postResponse = await axios.post(
        `${BASE_URL}linkedin/create-linkedin-post`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${linkedinAccessToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!postResponse.data.success) {
        throw new Error("Failed to create LinkedIn post");
      }

      toast.success("Carousel PDF posted to LinkedIn successfully!");
      const dbPostData = {
        userId: linkedinId,
        postUrn: postResponse.data.postUrn,
        postContent: postData.commentary,
        connectionType: "LinkedIn",
        postType: "carousel",
        postTag: "LinkedIn Post",
        media: [URL.createObjectURL(pdfBlob)],
        status: "PUBLISH",
        date_time: new Date().toISOString().split(".")[0],
        platformPost: {
          linkedin: true,
        },
      };

      // Save the post to the database
      toast.info("Saving post to the database...");
      const savePostResponse = await axios.post(
        `${BASE_URL}published/post-create`,
        dbPostData
      );

      // Only reset slides after everything is successful
      if (savePostResponse.status === 200) {
        dispatch(resetSlides());
      }

    } catch (err) {
      console.error("Error posting to LinkedIn:", err);
      toast.error(`Failed to post carousel to LinkedIn: ${err.message}`);
    } finally {
      setisLoading(false);
      setIsPosting(false);
      dispatch(isLoading(false));
    }
  };

  const handleTargetAudienceChange = (targetAudience) => {
    setTargetAudience(targetAudience);
  };
  const handleSlides = (e) => {
    const value = parseInt(e.target.value);
    setSlides(value);
    setSlidesError(value < 3 || value > 15);
  };

  const generatePDF = async () => {
    // Hide all Grammarly extension elements
    const grammarlyElements = document.querySelectorAll("grammarly-extension");
    grammarlyElements.forEach((element) => (element.style.display = "none"));

    // Dispatch loading state
    dispatch(isLoading(true));

    try {
      const slides = document.querySelectorAll(".capture-container");
      if (slides.length === 0) {
        dispatch(isLoading(false));
        return;
      }

      // Get dimensions of the first slide to set PDF size
      const firstSlide = slides[0];
      const slideWidth = firstSlide.offsetWidth;
      const slideHeight = firstSlide.offsetHeight;
      const aspectRatio = slideWidth / slideHeight;

      // Set PDF size to match slide aspect ratio with higher quality
      const pdfWidth = 297; // A3 width in mm for better quality
      const pdfHeight = pdfWidth / aspectRatio;

      const pdf = new jsPDF({
        orientation: pdfHeight > pdfWidth ? "portrait" : "landscape",
        unit: "mm",
        format: [pdfWidth, pdfHeight],
        compress: true,
        precision: 16
      });

      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i];

        // Create a canvas from the slide with enhanced quality
        const canvas = await html2canvas(slide, {
          scale: 8, // Increased scale for better quality
          useCORS: true,
          logging: false,
          backgroundColor: null,
          allowTaint: true,
          imageTimeout: 0,
          windowWidth: slideWidth * 2,
          windowHeight: slideHeight * 2,
          onclone: (clonedDoc) => {
            // Ensure all images are loaded in cloned document
            const images = clonedDoc.getElementsByTagName('img');
            return Promise.all(Array.from(images).map(img => {
              if (img.complete) return Promise.resolve();
              return new Promise((resolve) => {
                img.onload = resolve;
                img.onerror = resolve;
              });
            }));
          }
        });

        // Added the slide to the PDF with enhanced quality
        if (i > 0) {
          pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? "portrait" : "landscape");
        }

        // Convert canvas to high-quality image
        const imgData = canvas.toDataURL('image/jpeg', 1.0);
        
        pdf.addImage(
          imgData,
          'JPEG',
          0,
          0,
          pdfWidth,
          pdfHeight,
          `slide-${i}`,
          'FAST',
          0
        );
      }

      // Grammarly extension
      grammarlyElements.forEach((element) => (element.style.display = ""));

      // Return the generated PDF
      dispatch(isLoading(false));
      setShowDialog2(false);

      return pdf;
    } catch (error) {
      console.error('Error generating PDF:', error);
      dispatch(isLoading(false));
      toast.error('Error generating PDF. Please try again.');
      return null;
    }
  };

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [previewPDF, setPreviewPDF] = useState(null);

  // const handlePreviewAndDownload = async () => {
  //   dispatch(isLoading(true));
  //   try {
  //     const grammarlyElements = document.querySelectorAll("grammarly-extension");
  //     grammarlyElements.forEach((element) => (element.style.display = "none"));

  //     const pdf = await generatePDF();
  //     if (pdf) {
  //       // Convert PDF to data URL for preview
  //       const pdfDataUrl = pdf.output('dataurlstring');
  //       setPreviewPDF(pdfDataUrl);
  //       setShowPreviewModal(true);
  //     }
  //   } catch (error) {
  //     console.error('Error generating PDF preview:', error);
  //     toast.error('Error generating PDF preview. Please try again.');
  //   } finally {
  //     const grammarlyElements = document.querySelectorAll("grammarly-extension");
  //     grammarlyElements.forEach((element) => (element.style.display = ""));
  //     dispatch(isLoading(false));
  //   }
  // };

  const handleDownloadAfterPreview = () => {
    if (previewPDF) {
      const filename = fileName || 'carousel';
      // Convert data URL back to PDF and download
      const binary = atob(previewPDF.split(',')[1]);
      const array = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        array[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([array], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${filename}.pdf`;
      link.click();
      URL.revokeObjectURL(url);
      setShowPreviewModal(false);
      toast.success('PDF downloaded successfully!');
    }
  };

  const updateCreditHistory = async (creditInfo) => {
    try {
      const topicCredit = await getApi(
        "creditpoint/point-info/Carousel-Generation"
      );
      const transactionData = await getApi(
        `transaction/get-subscription-by-id/${user.userData.teamId}`
      );

      const bodyPost = {
        teamId: user.userData.teamId,
        name: transactionData[0].plan_Name,
        subscriptionId: creditInfo.data.subscriptionId,
        userId: user.userData.linkedinId,
        crpoint: `${topicCredit.data.point}`,
        transaction: "Carousel-Generation",
      };
      // console.log(bodyPost);
      await postApi("credit/create-credit-history", bodyPost);
    } catch (error) {
      console.warn("update Credit History", error);
    }
  };

  const handleFormSubmission = async (e) => {
    e.preventDefault();

    let creditInfo;

    try {
      // Fetch credit information for the user's team
      creditInfo = await getApi(
        `credit/check-credit-point/${user.userData.teamId}`
      );

      if (creditInfo.status === false) {
        throw new Error("Error fetching credit information");
      }

      // Check if the response code is 403 (trail period expired)
      if (creditInfo.status === 403) {
        toast.error("Trial period has expired. Please subscribe to continue.");
        return;
      }
    } catch (error) {
      console.error("Error fetching credit information:", error);
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      return;
    }

    if (!tone) setToneError(true);
    if (tone && targetAudience && !slidesError) {
      if (active == "Text") {
        const data = {
          text: text,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostText(data, setisLoading));
        console.log(data);
      } else if (active == "Topic") {
        const data = {
          topic: topic,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostTopic(data, setisLoading));
        console.log(data);
      } else if (active == "Article") {
        const data = {
          articleUrl: articleLink,
          tone: tone,
          targetAudience: targetAudience,
          slides: slides,
        };
        setisLoading(true);
        dispatch(aiPostArticle(data, setisLoading));
        console.log(data);
      }
    }
    updateCreditHistory(creditInfo);
  };

  const [showDialog2, setShowDialog2] = useState(false);
  const closeDialog2 = () => {
    setShowDialog2(false);
    setActive("upload");
    setSearch("");
  };

  const handleTopicChange = (e) => {
    const newTopic = e.target.value;
    console.log(newTopic);
    if (newTopic.length <= 50) {
      setTopic(newTopic);
      setTopicLimitExceeded(false);
    } else {
      toast.warning(`Max 50 characters allowed`);
    }
  };

  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);

  const [showCarouselPreview, setShowCarouselPreview] = useState(false);

  const handleSaveAsPNG = async () => {
    dispatch(isLoading(true));
    try {
      const slides = document.querySelectorAll(".capture-container");
      if (slides.length === 0) return;
      
      // Create a canvas with higher quality settings
      const canvas = await html2canvas(slides[0], {
        scale: 8, // Increased scale for better quality
        useCORS: true,
        logging: false,
        backgroundColor: null,
        allowTaint: true,
        imageTimeout: 0,
        windowWidth: slides[0].offsetWidth * 2,
        windowHeight: slides[0].offsetHeight * 2,
        onclone: (clonedDoc) => {
          // Ensure all images are loaded in cloned document
          const images = clonedDoc.getElementsByTagName('img');
          return Promise.all(Array.from(images).map(img => {
            if (img.complete) return Promise.resolve();
            return new Promise((resolve) => {
              img.onload = resolve;
              img.onerror = resolve;
            });
          }));
        }
      });
      
      // Convert to high-quality PNG
      const link = document.createElement('a');
      link.download = `${fileName || 'carousel'}.png`;
      link.href = canvas.toDataURL('image/png', 1.0);
      link.click();
      toast.success('PNG downloaded successfully!');
    } catch (error) {
      console.error('Error saving as PNG:', error);
      toast.error('Error saving as PNG. Please try again.');
    } finally {
      dispatch(isLoading(false));
    }
  };

  const handleExportToZapier = () => {
    // Add Zapier export logic here
    toast.info('Zapier export coming soon!');
  };

  const handleExportToMake = () => {
    // Add Make export logic here
    toast.info('Make export coming soon!');
  };

  return (
    <>
      <Modal
        show={schedulePanelOpen}
        onHide={() => setIsPopupOpen(true)}
        backdrop={false}
        keyboard={false}
        dialogClassName={`custom-width-modal-40 ${
          isDark ? "" : "isdark-model"
        }`}
       >
        <Modal.Header closeButton>
          <Modal.Title>Post Linkedin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            rows="10"
            className="form-control description-textarea w-100"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="Enter your description here..."
          ></textarea>
          <div className="d-flex justify-content-start mt-2">
            <button
              className={`ai-assist-button ${
                isDark ? "publish-post-light" : "publish-post-dark"
              } p-2 gradientBorderFinal`}
              onClick={() => setAiAssistOpen(true)}
            >
              AI Assist
            </button>
          </div>
          <div className="mt-3">
            <input
              type="text"
              className="form-control"
              value={fileName}
              onChange={handleFileNameChange}
              placeholder="Enter file name here..."
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="">
            <button
              className={`${
                isDark ? "publish-post-light" : "publish-post-dark"
              } p-2 gradientBorderFinal text-[0.875rem]`}
              onClick={postToLinkedIn}
            >
              Post to LinkedIn
            </button>
            <div className="btn-group">
              <button
                className="gradient-button-new text-white p-2 px-3 text-[0.875rem]"
                style={{
                  borderRadius: "6px 0 0 6px",
                  border: "1px solid gray",
                  width: "300px",
                }}
                onClick={autoScheduleAndPost}
              >
                Auto-Schedule
              </button>
              <button
                className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                style={{ backgroundColor: "#39B2FF" }}
                id="dropdownMenuReference"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-reference="parent"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul
                className="dropdown-menu grediant-button w-full"
                aria-labelledby="dropdownMenuReference"
                style={{ cursor: "pointer", padding: 0, zIndex: 100000000 }}
              >
                <li
                  onClick={schedulePostHander}
                  className="flex justify-center items-center gradient-button-new p-3"
                  style={{
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    borderRadius: "6px",
                  }}
                >
                  Custom-Schedule
                </li>
              </ul>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Second Modal */}
      <Modal
        show={schedulePanelOpen}
        onHide={() => setIsPopupOpen(true)}
        backdrop={false} // Allow interaction with both modals
        keyboard={false}
        dialogClassName={`custom-width-modal-30 ${
          !isDark ? "" : "isdark-model"
        } second-modal`}
      >
        <Modal.Header>
          <Modal.Title>Preview Post</Modal.Title>
        </Modal.Header>
        <Modal.Body className="padded-modal-body">
          <div className="post-preview-container p-3 border rounded">
            <div className="d-flex align-items-center mb-3">
              <img
                src={
                  user?.userPhoto?.length > 0
                    ? user?.userPhoto
                    : `${IMAGE_URL}/images/Icons/avatar.jpg`
                }
                alt="User"
                className="rounded-circle me-2"
                style={{ width: "50px", height: "50px" }}
              />
              <div>
                <h5 className="m-0">{user?.userData?.userName}</h5>
                <small className="text-muted">
                  {" "}
                  {user?.userData?.localizedHeadline?.length > 50
                    ? `${user?.userData?.localizedHeadline.slice(0, 50)}...`
                    : user?.userData?.localizedHeadline}{" "}
                </small>
              </div>
            </div>

            <div className="mb-3">
              <p>{description}</p>
            </div>

            <div
              ref={scrollContainerRef}
              className="carousel-preview mb-3 border rounded position-relative"
            >
              <div className="carousel-navbar bg-light text-center p-2">
                File Name | {upper.slides.length}
              </div>

              <div className="carousel-page d-flex justify-content-center"></div>

              {currentSlide > 0 && (
                <button
                  className="carousel-btn-prev position-absolute arrow-circle"
                  onClick={prevSlide}
                >
                  <i className="fa fa-chevron-left"></i>
                </button>
              )}
              {currentSlide < upper.slides.length - 1 && (
                <button
                  className="carousel-btn-next position-absolute arrow-circle"
                  onClick={nextSlide}
                >
                  <i className="fa fa-chevron-right"></i>
                </button>
              )}
            </div>

            <div className="page-info text-center mt-2">
              Page {currentSlide + 1} / {upper.slides.length}
            </div>

            <div className="d-flex justify-content-between mt-3 border-top pt-3">
              <div className="d-flex align-items-center">
                <i className="fa fa-thumbs-up me-2"></i>
                <span>Like</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-comment-dots me-2"></i>
                <span>Comment</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-retweet me-2"></i>
                <span>Repost</span>
              </div>
              <div className="d-flex align-items-center">
                <i className="fa fa-paper-plane me-2"></i>
                <span>Send</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* AI Assist Popup */}
      <Modal
        show={aiAssistOpen}
        onHide={() => setAiAssistOpen(false)}
        backdrop={false}
        keyboard={false}
        className="ai-assist-modal"
      >
        <Modal.Body className="p-4">
          <div className="d-flex flex-column ai-assist-content">
            <h5>AI Assist</h5>
            <input
              type="text"
              onChange={handleAiTextValueChange}
              value={AiTextValue}
              className="form-control my-3"
              placeholder="Write your topic here..."
            />
            <button
              className="btn btn-primary align-self-end"
              onClick={handleGenerate}
            >
              Generate
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDialog2}
        onHide={closeDialog2}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header
          closeButton
          className={`${isDark ? "text-black" : "text-white"}`}
        >
          Carousel
        </Modal.Header>
        <Modal.Body>
          <div className="flex overflow-x-scroll"></div>
          <button
            className={`text-white  mt-4 float-right gradient-button-new p-2 rounded-md`}
            onClick={handleDownloadAfterPreview}
          >
            Download PDF
          </button>
        </Modal.Body>
      </Modal>

      {/* Download Preview Modal */}
      <Modal
        show={showPreviewModal}
        onHide={() => setShowPreviewModal(false)}
        size="lg"
        centered
        className={isDark ? "" : "isdark-model"}
       >
        <Modal.Header closeButton>
          <Modal.Title>PDF Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {previewPDF && (
            <div style={{ height: '70vh', overflow: 'auto' }}>
              <iframe
                src={previewPDF}
                style={{ width: '100%', height: '100%', border: 'none' }}
                title="PDF Preview"
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="gradient-button-new text-white p-2 px-3 rounded-md"
            onClick={handleDownloadAfterPreview}
          >
            Download PDF
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showCarouselPreview}
        onHide={() => setShowCarouselPreview(false)}
        size="xl"
        centered
        className={isDark ? "" : "isdark-model"}
       >
        <Modal.Header closeButton>
          <Modal.Title>Carousel Preview</Modal.Title>
          <div className="flex gap-2 ml-auto">
            <button
              className="px-3 py-1 rounded-md border border-gray-300 hover:bg-gray-100"
              onClick={handleSaveAsPNG}
            >
              Save as PNG
            </button>
            <button
              className="px-3 py-1 rounded-md border border-gray-300 hover:bg-gray-100"
              onClick={() => {
                const downloadPDF = async () => {
                  dispatch(isLoading(true));
                  try {
                    const pdf = await generatePDF();
                    if (pdf) {
                      pdf.save(`${fileName || 'carousel'}.pdf`);
                      toast.success('PDF downloaded successfully!');
                    }
                  } catch (error) {
                    console.error('Error downloading PDF:', error);
                    toast.error('Error downloading PDF. Please try again.');
                  } finally {
                    dispatch(isLoading(false));
                  }
                };
                downloadPDF();
              }}
              style={{ zIndex: 9000000 }}
            >
              Download PDF
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="carousel-preview-container">
            <div className="flex overflow-x-auto gap-4 p-4">
              {upper.slides.map((slide, index) => (
                <div
                  key={index}
                  className="capture-container flex-shrink-0"
                  style={{
                    width: '300px',
                    height: '300px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    position: 'relative'
                  }}
                >
                  {/* Render slide content */}
                  <div 
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: verticalAlignment || 'center',
                      alignItems: textAlignment || 'center',
                      padding: '2rem',
                      background: bgColor || 'transparent'
                    }}
                   >
                    {pattern && (
                      <PatternContainer
                        pattern={pattern}
                        bgOpacity={bgOpacity}
                        color={boxColor}
                      />
                    )}
                    {slide.image && (
                      <img 
                        src={slide.image} 
                        alt=""
                        style={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'contain'
                        }}
                      />
                    )}
                    <div className="slide-content" style={{ zIndex: 1 }}>
                      {slide.subtitle && (
                        <p style={{
                          fontSize: subtitleSize || '1rem',
                          fontFamily: bodyFont || 'inherit',
                          color: subTitleColor || 'inherit',
                          margin: '0.5rem 0'
                        }}>
                          {slide.subtitle}
                        </p>
                      )}
                      {slide.title && (
                        <h2 style={{
                          fontSize: titleSize || '2rem',
                          fontFamily: titleFont || 'inherit',
                          color: titleColor || 'inherit',
                          margin: '0.5rem 0'
                        }}>
                          {slide.title}
                        </h2>
                      )}
                      {slide.desc && (
                        <p style={{
                          fontSize: descSize || '1rem',
                          fontFamily: bodyFont || 'inherit',
                          color: descColor || 'inherit',
                          margin: '0.5rem 0'
                        }}>
                          {slide.desc}
                        </p>
                      )}
                    </div>
                  </div>
                  <div 
                    className="slide-number" 
                    style={{
                      position: 'absolute',
                      bottom: '1rem',
                      right: '1rem',
                      color: pageNoColor || 'inherit'
                    }}
                  >
                    {index + 1}/{upper.slides.length}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="py-6 px-3 flex flex-col gap-3 overflow-y-auto">
        <p className="font-bold">AI Generation</p>

        <div className="flex flex-col">
          <div className="flex justify-between px-3">
            <span
              className={`${
                active == "Topic"
                  ? "border-b-2 gradient-border-bottom"
                  : "border-b-2 border-white"
              } cursor-pointer ${
                isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
              } hover:rounded-t-md p-2 px-3`}
              onClick={() => setActive("Topic")}
            >
              Topic
            </span>
            <span
              className={`${
                active == "Text"
                  ? "border-b-2 gradient-border-bottom"
                  : "border-b-2 border-white"
              } cursor-pointer ${
                isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
              } hover:rounded-t-md p-2 px-3`}
              onClick={() => setActive("Text")}
            >
              Paragraph
            </span>
            <span
              className={`${
                active == "Article"
                  ? "border-b-2 gradient-border-bottom"
                  : "border-b-2 border-white"
              } cursor-pointer ${
                isDark ? "hover:bg-gray-100" : "hover:bg-gray-400"
              } hover:rounded-t-md p-2 px-3`}
              onClick={() => setActive("Article")}
            >
              Article
            </span>
          </div>
          <hr></hr>
        </div>
        <form
          className="flex flex-col gap-4"
          onSubmit={(e) => handleFormSubmission(e)}
        >
          {active == "Topic" ? (
            <div className="flex flex-col w-full gap-2 text-sm">
              <label
                className="text-sm font-semibold"
                style={{ color: isDark ? "black" : "white" }}
              >
                Enter a Topic (max 50 characters)
              </label>
              <Input
                type="text"
                value={topic}
                onChange={handleTopicChange}
                placeholder="e.g. Innovative Startup Ideas"
                style={{
                  background: "transparent",
                  color: isDark ? "black" : "white",
                }}
                required={true}
                // maxLength={50}
              />
              {topicLimitExceeded && (
                <p className="text-xs text-red-500">
                  Topic is limited to 50 characters.
                </p>
              )}
            </div>
          ) : active == "Text" ? (
            <div className="flex flex-col w-full gap-2 text-sm">
              <label
                className="text-sm font-semibold"
                style={{ color: isDark ? "black" : "white" }}
              >
                Enter your text content
              </label>
              <Textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Your awesome content"
                rows={4}
                style={{
                  background: "transparent",
                  color: isDark ? "black" : "white",
                }}
                required={true}
              />
            </div>
          ) : (
            <div className="flex flex-col w-full gap-2 text-sm">
              <label
                className="text-sm font-semibold"
                style={{ color: isDark ? "black" : "white" }}
              >
                Enter an Article URL
              </label>
              <Input
                type="text"
                value={articleLink}
                onChange={(e) => setArticleLink(e.target.value)}
                placeholder="https://www.example.com/article"
                style={{
                  background: "transparent",
                  color: isDark ? "black" : "white",
                }}
                required={true}
              />
            </div>
          )}
          <div className="flex flex-col gap-2  text-sm">
            <p className="text-sm font-semibold">Tone/Mood</p>
            <Select value={tone} onValueChange={handleToneChange}>
              <SelectTrigger
                className={`${isDark ? " text-black" : " text-white"}`}
              >
                <SelectValue placeholder="Creative" />
              </SelectTrigger>
              <SelectContent
                className={`${
                  isDark ? "bg-white text-black" : "bg-black text-white"
                }`}
              >
                <SelectItem value="Creative">Creative</SelectItem>
                <SelectItem value="Curious">Curious</SelectItem>
                <SelectItem value="Empathetic">Empathetic</SelectItem>
                <SelectItem value="Casual">Casual</SelectItem>
                <SelectItem value="Formal">Formal</SelectItem>
                <SelectItem value="Fun">Fun</SelectItem>
                <SelectItem value="Happy">Happy</SelectItem>
                <SelectItem value="Hopeful">Hopeful</SelectItem>
                <SelectItem value="Informal">Informal</SelectItem>
                <SelectItem value="Informative">Informative</SelectItem>
                <SelectItem value="Joyful">Joyful</SelectItem>
                <SelectItem value="Optimistic">Optimistic</SelectItem>
                <SelectItem value="Persuasive">Persuasive</SelectItem>
                <SelectItem value="Sarcastic">Sarcastic</SelectItem>
                <SelectItem value="Sincere">Sincere</SelectItem>
                <SelectItem value="Witty">Witty</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2 text-sm" required={true}>
            <p className="text-sm font-semibold">Target Audience</p>
            <Select
              value={targetAudience}
              onValueChange={handleTargetAudienceChange}
            >
              <SelectTrigger
                className={`${isDark ? " text-black" : " text-white"}`}
              >
                <SelectValue placeholder="Beauty Enthusiasts" />
              </SelectTrigger>
              <SelectContent
                className={`${
                  isDark ? "bg-white text-black" : "bg-black text-white"
                }`}
              >
                <SelectItem value="Beauty Enthusiasts">
                  Beauty Enthusiasts
                </SelectItem>
                <SelectItem value="Entrepreneurs">Entrepreneurs</SelectItem>
                <SelectItem value="Environmentalists">
                  Environmentalists
                </SelectItem>
                <SelectItem value="Fitness Enthusiasts">
                  Fitness Enthusiasts
                </SelectItem>
                <SelectItem value="Foodies">Foodies</SelectItem>
                <SelectItem value="Gamers">Gamers</SelectItem>
                <SelectItem value="General Public">General Public</SelectItem>
                <SelectItem value="Gen Z">Gen Z</SelectItem>
                <SelectItem value="Millennials">Millennials</SelectItem>
                <SelectItem value="Professionals">Professionals</SelectItem>
                <SelectItem value="Students">Students</SelectItem>
                <SelectItem value="Tech Enthusiasts">
                  Tech Enthusiasts
                </SelectItem>
                <SelectItem value="Travelers">Travelers</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex flex-col gap-2 text-sm -mt-3">
            <label
              className="text-sm font-semibold"
              style={{ color: isDark ? "black" : "white" }}
            >
              Slides
            </label>
            <input
              type="number"
              className="w-[290px] text-sm border-[1px] border-gray-200 rounded-md p-2 outline-none"
              placeholder=""
              value={slides}
              onChange={(e) => handleSlides(e)}
              style={{
                background: "transparent",
                color: isDark ? "black" : "white",
              }}
              required={true}
            ></input>
            {slidesError && (
              <p className="text-xs" style={{ color: "red" }}>
                Slides should be greater than 2 and less than 16
              </p>
            )}
          </div>
          <Button
            type="submit"
            className="gradient-button-new flex items-center justify-center gap-2 hover:bg-green-800"
            disabled={isLoadingLocal ? true : false}
          >
            {isLoadingLocal ? "" : <img src={aiImg}></img>}
            {isLoadingLocal ? (
              <div className="flex justify-center items-center gap-1">
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                <p> Generating...</p>
              </div>
            ) : (
              `Generate from ${
                active == "Text"
                  ? "Text"
                  : active == "Topic"
                  ? "Topic"
                  : "Article"
              }`
            )}
          </Button>
        </form>
        <div className="flex flex-col gap-3">
          <button
            className="gradient-button-new text-white p-2 px-2 rounded-md text-[0.875rem]"
            style={{
                // border: "1px solid gray",
              }}
              onClick={() => setIsEditOverlayOpen(true)}
          >
            <span className="text">Create Post</span>
          </button>

          <EditPostOverlay 
            isOpen={isEditOverlayOpen}
            onClose={() => setIsEditOverlayOpen(false)}
          />

          {/* <div className="btn-group">
            <button
              className="gradient-button-new text-white p-2 px-3 text-[0.875rem]"
              style={{
                borderRadius: "6px 0 0 6px",
                border: "1px solid gray",
                width: "300px",
              }}
              onClick={autoScheduleAndPost}
            >
              Auto-Schedule
            </button>
            <button
              className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
              style={{ backgroundColor: "#39B2FF" }}
              id="dropdownMenuReference"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent"
            >
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
            <ul
              className="dropdown-menu grediant-button w-full"
              aria-labelledby="dropdownMenuReference"
              style={{ cursor: "pointer", padding: 0, zIndex: 100000000 }}
            >
              <li
                onClick={schedulePostHander}
                className="flex justify-center items-center gradient-button-new p-3"
                style={{
                  fontSize: "0.8rem",
                  cursor: "pointer",
                  borderRadius: "6px",
                }}
              >
                Custom-Schedule
              </li>
            </ul>
          </div> */}

          <button
            className="gradient-button-new text-white p-2 px-2 rounded-md text-[0.875rem]"
            onClick={() => setShowCarouselPreview(true)}
            style={{ zIndex: 9000000 }}
           >
            Download
          </button>

          {/* <button
            className="gradient-button-new text-white p-2 px-2 rounded-md text-[0.875rem]"
            onClick={() => {
              const downloadPDF = async () => {
                dispatch(isLoading(true));
                try {
                  const pdf = await generatePDF();
                  if (pdf) {
                    pdf.save(`${fileName || 'carousel'}.pdf`);
                    toast.success('PDF downloaded successfully!');
                  }
                } catch (error) {
                  console.error('Error downloading PDF:', error);
                  toast.error('Error downloading PDF. Please try again.');
                } finally {
                  dispatch(isLoading(false));
                }
              };
              downloadPDF();
            }}
            style={{ zIndex: 9000000 }}
           >
            Download PDF Directly
          </button> */}
        </div>
      </div>
    </>
  );
}

export default AiBar;
