import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayCreatePost from "../../../components/OverlayCreatePost";
import { useSelector } from "react-redux";
import "../sideBar/EditPostOverlay.css";
import { Fade } from "react-bootstrap";
import { useDispatch } from "react-redux";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import { isLoading } from "../../../store/features/common/commonSlice";

import { IMAGE_URL } from "../../../api/API";

const EditPostOverlay = ({ isOpen, onClose }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [pdfName, setPdfName] = useState("Untitled PDF");
  const [pdfPreview, setPdfPreview] = useState(null);
  const previewRef = useRef(null);
  const upper = useSelector((s) => s.adjustReducer);
  const user = useSelector((state) => state.user);
  const { isDark } = useSelector((state) => state.common);
  const dispatch = useDispatch();


  const [isExpanded, setIsExpanded] = useState(false);
  const post = useSelector((state) => state.post);


  // const nextSlide = () => {
  //   if (currentSlide < upper.slides.length - 1) {
  //     setCurrentSlide(currentSlide + 1);
  //   }
  // };

  const handleNameChange = (e) => {
    setPdfName(e.target.value);
  };

  const generatePreview = async () => {
    const slides = document.querySelectorAll('.capture-container');
    if (slides.length === 0) return;

    const firstSlide = slides[currentSlide];

    try {
      const canvas = await html2canvas(firstSlide, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: null
      });

      setPdfPreview(canvas.toDataURL('image/jpeg', 0.95));
    } catch (error) {
      console.error('Error generating preview:', error);
    }
  };

  const generatePDF = async () => {
    dispatch(isLoading(true));

    const slides = document.querySelectorAll('.capture-container');
    if (slides.length === 0) return;

    // Get dimensions of the first slide to set PDF size
    const firstSlide = slides[0];
    const slideWidth = firstSlide.offsetWidth;
    const slideHeight = firstSlide.offsetHeight;
    const aspectRatio = slideWidth / slideHeight;

    // Set PDF size to match slide aspect ratio
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = pdfWidth / aspectRatio;

    const pdf = new jsPDF({
      orientation: pdfHeight > pdfWidth ? 'portrait' : 'landscape',
      unit: 'mm',
      format: [pdfWidth, pdfHeight]
    });

    for (let i = 0; i < slides.length; i++) {
      const slide = slides[i];

      // Create a canvas from the slide
      const canvas = await html2canvas(slide, {
        scale: 6, // Increase scale for better quality
        useCORS: true,
        logging: false, // Disable logging for better performance
        backgroundColor: null // Transparent background
      });

      // Add the slide to the PDF
      if (i > 0) {
        pdf.addPage([pdfWidth, pdfHeight], pdfHeight > pdfWidth ? 'portrait' : 'landscape');
      }
      pdf.addImage(
        canvas.toDataURL('image/jpeg', 0.95), // Use JPEG with 95% quality for smaller file size
        'JPEG',
        0, 0, pdfWidth, pdfHeight,
        undefined,
        'FAST' // Use fast compression
      );
    }
    pdf.save(pdfName + ".pdf");

    dispatch(isLoading(false));
  };

  // Generate preview when current slide changes
  React.useEffect(() => {
    generatePreview();
  }, [currentSlide]);

  // Generate preview when modal opens when user clicks (fixed the first page blank problem)
  React.useEffect(() => {
    if (isOpen) {
      generatePreview();
    }
  }, [isOpen]);

  return (
    <Modal
    show={isOpen}
    onHide={onClose}
    size="xl"
    backdrop={true}
    keyboard={true}
    dialogClassName={`edit-post-modal ${!isDark ? 'dark-theme' : ''}`}
    animation={Fade}
    style={{ marginLeft: "1rem", marginRight: "1rem" }}
   >
      {/* <Modal.Header 
      closeButton
      style={{ backgroundColor: isDark ? 'white' : '#1a1a1a' }}
      >
        <Modal.Title className="text-base" style={{ color: isDark ? 'black' : 'white' }}>Edit Post</Modal.Title>
      </Modal.Header> */}

      <Modal.Body className={`p-1 ${!isDark ? 'bg-[#1a1a1a]' : ''}`} style={{ borderRadius: "0.5rem" }}>
        <div className="split-view">
         <div
            className={`edit-section ${activeSection === 'create' ? 'active-section' : ''} ${!isDark ? 'bg-[#1a1a1a]' : ''}`}
            onClick={() => setActiveSection('create')}
           >


            <OverlayCreatePost 
              title={pdfName} 
              onClose={onClose}
              getTwitterUser={user?.userData}
              getFacebookUser={user?.userData}
              getInstaUser={user?.userData}
            />

            <div className="pdf-edit-section">
              <h2 className={`text-base mb-2 ${!isDark ? 'text-gray-300' : 'text-gray-600'}`}>Give your Amazing Carousel a Name:</h2>
              <div className={`border rounded-lg p-2 ${!isDark ? 'bg-[#1a1a1a] border-gray-600' : 'bg-gray-100'} mt-1`}>
                <input
                  type="text"
                  value={pdfName}
                  onChange={handleNameChange}
                  className={`pdf-title-input w-full border-none bg-transparent focus:outline-none ${!isDark ? 'text-white' : ''}`}
                  placeholder="Edit PDF Name"
                />
                {/* <button
                  onClick={generatePDF}
                  className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
                  disabled={upper.isLoading}
                >
                  Generate PDF
                </button> */}
              </div>
            </div>
          </div>



          <div className={`divider-vertical ${!isDark ? 'border-gray-700 w-[1px] opacity-50' : 'w-[1px] opacity-80'}`} />

          <div
            className={`preview-section ${activeSection === 'preview' ? 'active-section' : ''} ${!isDark ? 'bg-[#1a1a1a]' : ''}`}
            onClick={() => setActiveSection('preview')}
          >
            <div>

              {/* <button
                onClick={onClose}
                className={`hover:bg-opacity-10 hover:bg-gray-500 transition-colors mb-3 ml-60`}
                >
                <i className={`fas fa-times text-xl ${isDark ? 'text-black' : 'text-white'}`}></i>
              </button> */}

              {/* Close button */}
                <button
                  onClick={onClose}
                  className="absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full bg-red-500 hover:bg-red-600 transition-colors"
                >
                  <i className="fas fa-times text-white"></i>
                </button>

              
              <div className="bg-white rounded-xl shadow-sm border border-gray-200 mt-10">
                

                <div className="p-4 pb-2">
                  <div className="flex items-start justify-between">
                    <div className="flex gap-3">
                      <img
                        src={user?.userPhoto || `${IMAGE_URL}/images/Icons/avatar.jpg`}
                        className="w-12 h-12 rounded-full border object-cover"
                        alt="Profile"
                      />

                      <div className="flex flex-col">
                        <span className="font-semibold text-[14px] text-black hover:text-blue-600 cursor-pointer">
                          {user?.userData?.userName?.length > 30
                            ? `${user?.userData?.userName.slice(0, 30)}...`
                            : user?.userData?.userName}
                        </span>

                        <span className="text-[10px] text-black">
                          {user?.userData?.localizedHeadline?.length > 30
                            ? `${user?.userData?.localizedHeadline.slice(0, 30)}...`
                            : user?.userData?.localizedHeadline}
                        </span>

                        <span className="text-[10px] text-black flex items-center gap-1">
                          Now •{" "}
                          <i className="fa-solid fa-earth-americas text-[10px]"></i>
                        </span>
                      </div>
                    </div>

                    <i className="fa-brands fa-linkedin text-[#0a66c2] text-xl"></i>
                  </div>
                </div>

                {/* Content */}

                <div className="px-4 pt-1">
                  {(() => {
                    // this is the function to get content from post state and overlaycreatepost
                    const content = post.overlayPostContent || post.postContent || "";
                    const contentLines = content.split('\n');
                    const visibleLines = contentLines;

                    return (
                      <div className="text-[12px] text-black">
                        {isExpanded ? (
                          <>
                            {visibleLines.map((line, index) => (
                              <div key={index} className="whitespace-pre-wrap break-words">
                                {line || '\u00A0'}
                              </div>
                            ))}
                            <button onClick={() => setIsExpanded(false)} className="text-blue-600">
                              <span className="text-[10px]"> View Less</span>
                            </button>
                          </>
                        ) : (
                          <>
                            {visibleLines.slice(0, 2).map((line, index) => (
                              <div key={index} className="whitespace-pre-wrap break-words">
                                {line || '\u00A0'}
                              </div>
                            ))}
                            {visibleLines.length > 2 && (
                              <button onClick={() => setIsExpanded(true)} className="text-blue-600">
                                <span className="text-[10px]"> View More</span>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })()}
                </div>

                {/* this is the carousel pdf preview */}

                <div className="carousel-preview">
                  <div className="carousel-header">
                    <i className="far fa-file-pdf"></i>
                    <div className="pdf-title">
                      <h4>{pdfName} • {upper.slides.length} pages</h4>
                    </div>
                  </div>

                  <div className="carousel-content" ref={previewRef}>
                    {pdfPreview ? (
                      <div className="pdf-preview-container">
                        <img
                          src={pdfPreview}
                          alt="PDF Preview"
                          className="w-full h-auto rounded-lg shadow-md"
                        />
                      </div>
                    ) : (
                      <div className="capture-container">
                        <div className="slide-content">
                          <h3>{upper.slides[currentSlide]?.title}</h3>
                          <p>{upper.slides[currentSlide]?.subtitle}</p>
                          <p>{upper.slides[currentSlide]?.desc}</p>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="carousel-footer">
                    <div className="page-counter">
                      {currentSlide + 1} / {upper.slides.length}
                    </div>
                    <div className="carousel-controls">
                      <button
                        className="nav-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentSlide(prev => prev > 0 ? prev - 1 : prev);
                        }}
                        disabled={currentSlide === 0}
                      >
                        <i className="fas fa-chevron-left"></i>
                      </button>
                      <button
                        className="nav-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentSlide(prev => prev < upper.slides.length - 1 ? prev + 1 : prev);
                        }}
                        disabled={currentSlide === upper.slides.length - 1}
                      >
                        <i className="fas fa-chevron-right"></i>
                      </button>
                    </div>
                  </div>

                </div>

                {/* this is the post action section */}
                <div className="grid grid-cols-4 -mx-1 mt-1">
                  <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
                    <i className="fa-regular fa-thumbs-up text-black"></i>

                    <span className="text-[9px] font-medium text-black">Like</span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
                    <i className="fa-regular fa-comment text-black"></i>

                    <span className="text-[9px] font-medium text-black">
                      Comment
                    </span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
                    <i className="fa-solid fa-retweet text-black"></i>

                    <span className="text-[9px] font-medium text-black">Repost</span>
                  </button>

                  <button className="flex items-center justify-center gap-2 py-3 hover:bg-gray-100 rounded-lg transition-colors group">
                    <i className="fa-regular fa-paper-plane text-black"></i>

                    <span className="text-[9px] font-medium text-black">Send</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

EditPostOverlay.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default EditPostOverlay;