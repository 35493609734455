import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { KeyRound, Eye, EyeOff, ArrowLeft } from 'lucide-react';
import { BASE_URL, IMAGE_URL } from '../../api/API';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    try {      
      const response = await axios.post(`${BASE_URL}userprofile/resetPassword`, {
        newPassword: password
      }, { withCredentials: true });

      console.log(response.data);
      setSuccess(true);

      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 3000);
    } catch (error) {
      setError('Failed to reset password. Please try again.');
      console.error('Reset password error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-800 to-gray-300">
      <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-2xl shadow-2xl backdrop-blur-lg backdrop-filter">
        <div className="text-center">
          <div className="flex justify-center items-center">
            <img 
              src={`${IMAGE_URL}/images/logo.svg`} 
              alt="logo" 
              className="w-36 h-auto p-2 mb-2"
            />
          </div>
          <h2 className="text-3xl font-extrabold text-gray-800 tracking-tight">
            {success ? 'Password Reset' : 'Reset Password'}
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {success 
              ? 'Your password has been reset successfully' 
              : 'Enter your new password below'}
          </p>
        </div>

        {success ? (
          <div className="text-center space-y-6">
            <div className="p-3 text-sm text-green-700 bg-green-100 rounded-md">
              Password reset successfully. Redirecting to dashboard...
            </div>
          </div>
        ) : (
          <form onSubmit={handleResetPassword} className="space-y-6">
            <div>
              <label 
                htmlFor="password" 
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                  placeholder="Enter your new password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-blue-600"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            <div>
              <label 
                htmlFor="confirmPassword" 
                className="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div className="relative">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  autoComplete="new-password"
                  required
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                  placeholder="Confirm your new password"
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-blue-600"
                >
                  {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            {error && (
              <div className="p-3 text-sm text-red-700 bg-red-100 rounded-md">
                {error}
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="animate-pulse">Resetting Password...</span>
              ) : (
                <span className="flex items-center">
                  <KeyRound className="mr-2" size={20} /> Reset Password
                </span>
              )}
            </button>
          </form>
        )}

        <div className="text-center">
          <button
            onClick={() => navigate('/login')}
            className="text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors flex items-center justify-center w-full"
          >
            <ArrowLeft size={16} className="mr-2" /> Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;