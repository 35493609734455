import React, { useState, useEffect } from "react";
import * as pdfjs from "pdfjs-dist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Set the worker for pdf.js
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const PdfCarousel = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(0);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const loadingTask = pdfjs.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        setNumPages(pdf.numPages);

        const pagePromises = [];
        for (let i = 1; i <= pdf.numPages; i++) {
          pagePromises.push(
            pdf.getPage(i).then((page) => ({
              pageNumber: i,
              pageContent: page,
            }))
          );
        }

        const pageData = await Promise.all(pagePromises);
        setPages(pageData);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [pdfUrl]);

  const renderPage = (pageContent) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const viewport = pageContent.getViewport({ scale: 1 });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      pageContent.render({ canvasContext: context, viewport }).promise.then(() => {
        const dataUrl = canvas.toDataURL();
        resolve(dataUrl);
      });
    });
  };

  useEffect(() => {
    const loadImages = async () => {
      const pageImages = await Promise.all(
        pages.map(async (page) => {
          const dataUrl = await renderPage(page.pageContent);
          return { ...page, imageUrl: dataUrl };
        })
      );
      setPages(pageImages);
    };

    if (pages.length > 0) {
      loadImages();
    }
  }, [pages]);

  // Custom arrows with smaller and more professional style
  const NextArrow = ({ onClick }) => (
    <div
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        background: "linear-gradient(135deg, #4A90E2, #007AFF)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: "bold",
        cursor: "pointer",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        transition: "all 0.3s ease",
        zIndex: 10,
      }}
      onClick={onClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.background = "linear-gradient(135deg, #357ABD, #0056CC)";
        e.currentTarget.style.transform = "translateY(-50%) scale(1.1)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = "linear-gradient(135deg, #4A90E2, #007AFF)";
        e.currentTarget.style.transform = "translateY(-50%) scale(1)";
        e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
      }}
    >
      ›
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div
      style={{
        position: "absolute",
        left: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        background: "linear-gradient(135deg, #4A90E2, #007AFF)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontSize: "18px",
        fontWeight: "bold",
        cursor: "pointer",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
        transition: "all 0.3s ease",
        zIndex: 10,
      }}
      onClick={onClick}
      onMouseEnter={(e) => {
        e.currentTarget.style.background = "linear-gradient(135deg, #357ABD, #0056CC)";
        e.currentTarget.style.transform = "translateY(-50%) scale(1.1)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.3)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.background = "linear-gradient(135deg, #4A90E2, #007AFF)";
        e.currentTarget.style.transform = "translateY(-50%) scale(1)";
        e.currentTarget.style.boxShadow = "0 2px 5px rgba(0, 0, 0, 0.2)";
      }}
    ></div>
  );

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "350px",
          height: "380px",
          border: "1px solid #ddd",
          borderRadius: "8px",
          overflow: "hidden",
          backgroundColor: "#f9f9f9",
          position: "relative",
        }}
      >
        {numPages > 0 ? (
          <Slider {...settings}>
            {pages.map((page) => (
              <div
                key={page.pageNumber}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#fff",
                }}
              >
                {page.imageUrl ? (
                  <img
                    src={page.imageUrl}
                    alt={`Page ${page.pageNumber}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#888",
                      textAlign: "center",
                    }}
                  >
                    Loading...
                  </p>
                )}
              </div>
            ))}
          </Slider>
        ) : (
          <p
            style={{
              fontSize: "14px",
              color: "#888",
              textAlign: "center",
              lineHeight: "300px",
            }}
          >
            Loading PDF...
          </p>
        )}
      </div>
    </div>
  );
};

export default PdfCarousel;
