import React, { useEffect, useState } from 'react'
import { getApi, patchApi, postApi } from '../../api/methods'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const Billing = (props) => {
  const userInfo = useSelector(state => state.user.userData)
  const { isDark } = useSelector((state) => state.common);
  const teamMembers = useSelector(state => state.user.teamMembers)
  // const createdAt = useSelector(state => state.user.s)
  // console.log({ userInfo });
  const navigate = useNavigate()
  const [getSubs, setSubs] = useState([])
  const filterActiveTeamMembers = teamMembers.filter((member=>member.ac_status === true))
  const [teamMembersCount, setTeamMembersCount] = useState(filterActiveTeamMembers?.length || 0)
  const [creditBalance, setCreditBalance] = useState(0)
  const getSubActive = getSubs?.find((item) => item.planStatus === true)
  // console.log({ getSubActive });
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
}).format(new Date(userInfo.createdAt));

  const [planDetails, setPlanDetails] = useState(null);

  // modify this getSubscription function to also set plan details
  const getSubscription = async () => {
    try {
      const response = await getApi(`transaction/get-subscription-by-id/${userInfo.teamId}`);
      setSubs(response);
      
      // this will find the active subscription and set plan details
      const activeSub = response?.find(item => item.planStatus === true);
      if (activeSub) {
        setPlanDetails({
          planType: activeSub.plan_Name, // this will show the plan name
          accountType: activeSub.plan_type // Monthly/Yearly
        });
      }
    } catch (err) {
      console.warn('Error fetching subscription:', err);
    }
  };

  // fetch credit point avaliable of team member
  const getCreditBalance = async () => {
    await getApi(`credit/check-credit-point/${userInfo.teamId}`)
      .then((res) => {
        setCreditBalance(res.data.balance)
      }).catch((err) => {
        console.warn(err);
      })
  }

  // for upgrade plan page should be redirect
  const handleUpdateSub = (subscriptionId) => {
    localStorage.setItem('subscriptionId', subscriptionId)
    navigate('/buy-subscription')
  }

  const handleUpdateUserSub = async () => {
    await patchApi('user/user-session', { linkedinId: userInfo?.linkedinId, subscription: false })
  }

  const cancelCreditHistory = async (id) =>{
    try{

            const bodyPost = { 
                "teamId" : userInfo.teamId, 
                "name" : 'Cancel',
                "subscriptionId" : id, 
                "userId" : userInfo.linkedinId, 
                "crpoint" : `0`
            }

        await postApi('credit/create-credit-history',bodyPost)
    }catch (error){
        console.warn('update Credit History', error)
    }
}
  const cancelTeamSubcription = async () =>{
    try{

            const bodyPost = { 
                "teamId" : userInfo.teamId, 
                "subscription" : false
            }

        await patchApi('user/team-subscribed',bodyPost)
        toast.success('Plan Cancelled!')
    }catch (error){
        console.warn('update team subscription cancel:', error)
        toast.error('Plan Cancelled failed!')
    }
}

const handleCancelSubscription = async () => {
  // Confirm cancellation with the user
  const isConfirmed = window.confirm("Are you sure you want to cancel the subscription?");
  if (isConfirmed) {
    // Check if there is an active subscription
    if (getSubActive?.subscriptionId) {
      // Send a request to cancel the subscription
      await patchApi('transaction/update-subscription', { subscriptionId: getSubActive?.subscriptionId })
        .then((res) => {
          // Update user session to reflect the subscription cancellation
          handleUpdateUserSub();
          // Cancel credit history for the subscription
          cancelCreditHistory(getSubActive?.subscriptionId);
          // Cancel team subscription
          cancelTeamSubcription();
          // Redirect to the accounts page with a specific invite code
          window.location.href = '/my-accounts?invite=3';
        })
        .catch((err) => {
          console.warn(err);
        });
    }
  }
};

// this function will get plan benefits based on plan type
const getPlanBenefits = (planType) => {
  switch (planType) {
    case 'Pro':
      return [
        'Upto 1 team member',
        '300 Credits/month',
        'AI Post Generation',
        'On-The-Go',
        'Discover posts from similar interests'
      ];
    case 'Creator':
      return [
        'Upto 5 team members',
        '1500 Credits/month',
        'AI Post Generation',
        'On-The-Go',
        'Discover posts from similar interests',
        'Team collaboration features',
        'Advanced analytics'
      ];
    case 'Growth':
      return [
        'Unlimited team members',
        'Unlimited Credits/month',
        'AI Post Generation',
        'On-The-Go',
        'Discover posts from similar interests',
        'Team collaboration features',
        'Advanced analytics',
        'Priority support',
        'Custom integrations'
      ];
    default:
      return [
        `Upto ${getSubActive?.No_Ac} team members`,
        `${getSubActive?.credits} Credits/month`,
        'AI Post Generation',
        'On-The-Go',
        'Discover posts from similar interests'
      ];
  }
};

  useEffect(() => {
    getSubscription()
    getCreditBalance()
  }, [])
  // console.log('userinfosa', userInfo.teamId);
  // console.log({ getSubActive });
  return (
    <>
      <div className='mb-3'>
        <p className='text-xs sm:text-lg'>All invoices will be send to the email address: <strong>{userInfo.email}</strong></p>
      </div>
      <div className={`${isDark ? 'invoice-mail' : 'invoice-mail-dark'} pl-0`}>
      </div>

      <div className='mt-3 mb-1 flex max-md:flex-col max-md:items-start items-center gap-1' >
        {/* <h3 className='text-md max-xs:left-[20px] sm:text-xl pr-4 whitespace-nowrap'>Your Plan:</h3> */}
        { getSubActive ?
          <div className={isDark ? 'plan-container':'plan-container-dark'} >
            <div className={` ${isDark ? 'plan-header':'plan-header-dark'}`}>
              <div className='d-flex justify-content-between m-5 mt-0 mb-0'>
                <p className='mb-0' style={{ color: '#000000' }}>
                  <strong>{getSubActive.plan_Name}</strong> <span>Plan</span>
                </p>
                {/* {getSubActive.plan_type && (
                  <span className='ml-2 text-sm text-gray-500'>
                    ({getSubActive.plan_type})
                  </span>
                )} */}
              </div>
            </div>
            <div className="max-md:text-[10px] plan-body ">

              <div className='plan-body-item'>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Expiry</h3>
                  <p>{getSubActive?.endDate?.replace(/-/g, '/')}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Member Since</h3>
                  <p>{formattedDate}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Credit Available</h3>
                  <p>{creditBalance}</p>
                </div>
                <div className={isDark ?'plan-body-item-content':'plan-body-item-content-dark'}>
                  <h3>Active Team Member</h3>
                  <p>{teamMembersCount}</p>
                </div>

              </div>
              <div className={isDark ?'plan-body-item':'plan-body-item-dark'}>
                <h3 className='m-3 font-bold'>BENEFITS</h3>
                <ul className='m-3'>
                  {getPlanBenefits(getSubActive?.plan_Name).map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              </div>



            </div>
            { 
              userInfo.teamPrimary &&
              <div className={isDark ? 'plan-btn':'plan-btn-dark'}>
                <button onClick={() => handleUpdateSub(getSubActive?.subscriptionId)} >Upgrade</button>
              </div>
            }
          </div>
          : 
          <>
            <div className='d-flex flex-column justify-content-center ml-10 w-[400px]'>
              <button 
                className='btn btn-outline-primary w-full' 
                onClick={() => handleUpdateSub(getSubActive?.subscriptionId)}
              >
                Buy Now
              </button>
            </div>
          </>
        }
      </div>
      {
        userInfo.teamPrimary && userInfo.subscription &&
        <div className={isDark ? 'plan-footer':'plan-footer-dark'}>
          <button className={`${isDark? 'text-white border ':'text-white border'} max-md:text-[10px]`} onClick={handleCancelSubscription} style={{visibility: getSubActive === undefined && 'hidden', backgroundColor:'red',borderColor:'red'}} >Cancel Subscription</button>
        </div>
      }
    </>
  )
}

export default Billing