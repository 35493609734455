import React, { useEffect, useRef, useState } from "react";
import { postApi, getApi, deleteApi } from "../../api/methods";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_BACKEND } from "../../api/API";
import {
  addMediaDis,
  addMediaTitle,
  addOriginalUrl,
  changePostType,
} from "../../store/features/post/postSlice";
import { isLoading } from "../../store/features/common/commonSlice";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import {
  pexelImageData,
  pixabayImageData,
  unsplashImageData,
  clearImages,
} from "../../store/features/media/mediaAction";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Check } from "lucide-react";
import MediaDropdown from "../../components/DropDown/MediaDropdown";

const Media = (props) => {
  const userInfo = useSelector((state) => state.user.userData);
  const { loading, isDark } = useSelector((state) => state.common);
  const post = useSelector((state) => state.post);
  const fileInputRef = useRef(null);
  const [isImgSelect, setIsImgSelect] = useState(true);
  const [isPreview, setIsPreview] = useState("");
  const [inPanel, setInPanel] = useState(false);
  const [getMedia, setGetMedia] = useState([]);
  const [message, setMessage] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [getUniqueMedia, setUniqueMedia] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [isProgress, setIsProgress] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchPlat, setSearchPlat] = useState("");
  const [selectedImages1, setSelectedImages1] = useState([]);
  const [isSelected, setIsSelected] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [isDeleteMedia, setIsDeleteMedia] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [allImages, setAllImages] = useState([]);
  const closeDialog = () => setShowDialog(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedAIImages, setSelectedAIImages] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const imagesList = useSelector((s) => s.media);
  const dispatch = useDispatch();

  const pathname = location.pathname === "/ai-content";

  useEffect(() => {
    console.log(window.location.pathname);
  });

  // Searching Imgaes with Unsplash, pixabay and pexels
  const handleSearch = (e) => {
    e.preventDefault();
    {
      searchPlat == "Pexels"
        ? dispatch(pexelImageData(searchText))
        : searchPlat == "Pixabay"
        ? dispatch(pixabayImageData(searchText))
        : dispatch(unsplashImageData(searchText));
    }
  };

  const handleClose = () => {
    setShow(false);
    setSelectedFiles([]);
    setIsImgSelect(true);
  };

  const handleClose1 = () => {
    setShow1(false);
    setSearchText("");
  };

  const handleShow = () => {
    setShow(true);
  };

  const handlePreview = (path) => {
    setUniqueMedia(path);
    setIsPreview(path);

    // Assuming that the `path` should be added as a URL, description, and title.
    // You might want to modify these based on actual use-case (e.g., generating different descriptions or titles).

    // dispatch(addOriginalUrl(path)); // Adding the path as the original URL
    dispatch(addMediaDis(path)); // Adding a generic description
    dispatch(addMediaTitle(path)); // Adding a generic title

    // If you only want to add the media once, you should remove the duplicated dispatch calls.

    // Optionally, handle the type of media if needed
    // const fileType = path.split('.').pop();
    // if (fileType === 'mp4') {
    //   dispatch(changePostType('VIDEO'));
    // } else {
    //   dispatch(changePostType('IMAGE'));
    // }

    if (props.isPreview) {
      props.setMediaPanel(false);
    }
  };

  const toggleImageSelection = (imageUrl) => {
    setSelectedAIImages((prev) => {
      if (prev.includes(imageUrl)) {
        return prev.filter((url) => url !== imageUrl);
      } else {
        return [...prev, imageUrl];
      }
    });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files);
    setSelectedFiles(files);
    setIsImgSelect(!isImgSelect);
  };

  const uploadArrayOfUrls = async () => {
    try {
      dispatch(isLoading(true));
      const res = await postApi(`media/upload-medias-url`, {
        userId: userInfo?.linkedinId,
        imageUrls: selectedImages1,
      });
      if (res?.status === true) {
        toast.success("Images uploaded successfully!");
        setShow1(false);
        getUserMedia();
        setSelectedImages1([]);
      } else {
        toast.error("Image upload failed");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Image upload failed");
    } finally {
      dispatch(isLoading(false));
      setIsProgress(false);
      setSelectedFiles([]);
      setIsImgSelect(true);
      handleClose();
    }
  };

  const handleImageChangemore = (e) => {
    const filess = e.target.files;
    const fileArray = Array.from(filess);
    setSelectedFiles((prev) => [...prev, ...fileArray]);
    setSelectedImages((prev) => [...prev, ...fileArray]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProgress(true);
    const formData = new FormData();
    selectedFiles.forEach((image) => {
      const tempImgName = uuidv4() + ".png";
      formData.append("imageName", new File([image], tempImgName, { type: image.type }));
    });
    formData.append("userId", userInfo.linkedinId);

    try {
      const response = await postApi("media/upload-medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response?.status === true) {
        toast.success("Image uploaded Successfully!");
        setShow(false);
        getUserMedia();
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      toast.error("Image upload failed!");
    } finally {
      dispatch(isLoading(false));
      setIsProgress(false);
      setSelectedFiles([]);
      setIsImgSelect(true);
      handleClose();
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    console.log(selectedImages1);
    const formData = new FormData();
    selectedImages1.forEach((image) => {
      formData.append("imageName", image);
    });
    formData.append("userId", userInfo.linkedinId);
    console.log(formData);
    try {
      const response = await postApi("media/upload-medias", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response?.status === true) {
        toast.success("Image uploaded Successfully!");
        setShow1(!show1);
        getUserMedia();
      }
      dispatch(isLoading(false));
      setIsProgress(false);
      setSelectedImages1([]);
      setIsImgSelect(true);
    } catch (error) {
      console.error(error);
      toast.error("Image upload failed!");
      setIsProgress(false);
    }
  };

  const handleFileSelect = () => {
    fileInputRef.current.click();
    toast.info("Please upload the selected files.");
  };

  const getUserMedia = async () => {
    await getApi(`media/get-media-byuserid/${userInfo?.linkedinId}`)
      .then((res) => {
        setGetMedia(res.data.media);
        if (res.data.media.length === 0) {
          setMessage("Media not uploaded yet!");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  useEffect(() => {
    if (userInfo.linkedinId) {
      getUserMedia();
    }
    if (!loading) {
      setIsImgSelect(true);
    }
  }, [loading, userInfo]);

  // Add this console log to see the media data
  useEffect(() => {
    console.log("getMedia:", getMedia);
  }, [getMedia]);

  const deleteMedia = (url, index) => {
    console.log("Deleting media at index:", index);
    setShowDialog(true);
    setDeleteId(url);
  };

  const deleteMediaModalYes = async () => {
    console.log(deleteId);
    const body = {
      userId: userInfo?.linkedinId,
      id: deleteId,
    };
    try {
      dispatch(isLoading(true));
      await deleteApi("media/delete-media-byuserid", body);
      dispatch(isLoading(false));
      setGetMedia("");
      setIsPreview("");
      toast.success("Media is deleted.");
    } catch (error) {
      dispatch(isLoading(false));
      console.warn("media not deleted:", error);
      toast.error("media not deleted!");
    }
    setShowDialog(false);
  };

  const handleCancelselected = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const deleteSelected = () => {
    const body = {
      userId: userInfo.linkedinId,
      id: isSelected,
    };
    console.log(body);
    try {
      dispatch(isLoading(true));
      deleteApi("media/delete-media-byuserid", body);
      dispatch(isLoading(false));
      setGetMedia("");
      setIsPreview("");
      toast.success("Media is deleted.");
    } catch (error) {
      dispatch(isLoading(false));
      console.warn("media not deleted:", error);
      toast.error("media not deleted!");
    }
  };

  const handleImages = (source) => {
    const check = allImages.includes(source);
    if (check) {
      const newArr = allImages.filter((image) => image !== source);
      setAllImages(newArr);
    } else {
      setAllImages([...allImages, source]);
    }
  };

  const uploadFiles = async () => {
    if (selectedAIImages.length === 0) {
      alert("Please select at least one image to upload.");
      return;
    }

    setIsUploading(true);

    try {
      selectedAIImages.forEach((imageUrl) => {
        const imageName = imageUrl.split("/").pop();
        setIsPreview(imageName);
        handlePreview(imageName);
      });
      setSelectedAIImages([]);
    } catch (error) {
      console.error("Error uploading images:", error);
      alert(`Failed to upload images. Error: ${error.message}`);
    } finally {
      toast.success("Image added successfully");
      setIsUploading(false);
    }
  };

  const getImageUrl = (imageNameOrUrl) => {
    if (imageNameOrUrl.startsWith("http")) {
      // It's already a full URL, so we need to extract the filename
      const filename = getFilenameFromUrl(imageNameOrUrl);
      return `${IMAGE_URL_BACKEND}/uploads/${filename}`;
    }
    // It's just the filename, so we can use it directly
    return `${IMAGE_URL_BACKEND}/uploads/${imageNameOrUrl}`;
  };

  return (
    <>
      <div className="media-container">
        <div className={isPreview ? "media-body" : "media-body-expand"}>
          <div className="d-flex justify-content-between media-sub-header">
            <h3 className="max-md:text-sm text-xl">We have safely saved it for you here</h3>
            <div className="flex space-x-4">
              <button className="upload-btn max-sm:text-[11px]" onClick={handleShow}>
                Upload Media
              </button>
            </div>
          </div>
          <div className="media-selector">
            <div className="media-body-img">
              {pathname ? (
                // Render for AI content page
                getMedia?.length > 0 ? (
                  getMedia?.map((item, index) => {
                    const imageUrl = getImageUrl(item.imageName);
                    return (
                      <div
                        key={index}
                        className={`media-body-item cursor-pointer ${
                          selectedAIImages.includes(imageUrl) ? "selected" : ""
                        }`}
                        onClick={() => toggleImageSelection(imageUrl)}
                      >
                        {item.imageName.split(".").pop().toLowerCase() === "mp4" ? (
                          <video
                            src={imageUrl}
                            className={`img-fluid ${
                              isDark ? "img-video-bg-light" : "img-video-bg-dark"
                            }`}
                            title={item.imageName}
                          />
                        ) : (
                          <img
                            src={imageUrl}
                            className={`img-fluid ${
                              isDark ? "img-video-bg-light" : "img-video-bg-dark"
                            }`}
                            alt={item.imageName}
                            title={item.imageName}
                          />
                        )}
                        {selectedAIImages.includes(imageUrl) && (
                          <div
                            className="img-checked"
                            style={{
                              position: "absolute",
                              top: "5px",
                              right: "5px",
                              backgroundColor: "black",
                              borderRadius: "50%",
                              padding: "6px",
                            }}
                          >
                            <img src="/images/tickImg.svg" alt="Tick" style={{ width: "20px" }} />
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <div className="media-not">
                    <h3>{message}</h3>
                  </div>
                )
              ) : // Render for other pages
              getMedia?.length > 0 ? (
                getMedia?.map((item, index) => (
                  <div
                    key={index}
                    className="media-body-item cursor-pointer"
                    onClick={() => handlePreview(item.imageName)}
                  >
                    {item?.imageName?.split(".").pop() === "mp4" ? (
                      <>
                        <video
                          src={`${IMAGE_URL_BACKEND}/uploads/${item.imageName}`}
                          className={`img-fluid ${
                            isDark ? "img-video-bg-light" : "img-video-bg-dark"
                          }`}
                          title={item.imageName}
                        />
                        {!props.isPreview && (
                          <i
                            className="fa-regular fa-trash-can media-delete-contain"
                            title="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteMedia(item._id);
                            }}
                          ></i>
                        )}
                      </>
                    ) : (
                      <>
                        <img
                          src={`${IMAGE_URL_BACKEND}/uploads/${item.imageName}`}
                          className={`img-fluid ${
                            isDark ? "img-video-bg-light" : "img-video-bg-dark"
                          }`}
                          alt=""
                          title={item.imageName}
                        />
                        {!props.isPreview && (
                          <i
                            className="fa-regular fa-trash-can media-delete-contain"
                            title="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteMedia(item._id);
                            }}
                          ></i>
                        )}
                      </>
                    )}
                  </div>
                ))
              ) : (
                userInfo.linkedinId && (
                  <div className="media-not">
                    <h3>{message}</h3>
                  </div>
                )
              )}
            </div>
          </div>
          {pathname && (
            <button
              className="flex gradient-button-new text-white rounded-md p-1 px-5 "
              style={{ marginLeft: "auto" }}
              onClick={uploadFiles}
              disabled={isUploading || selectedAIImages.length === 0}
            >
              {isUploading ? "Uploading..." : `Upload ${selectedAIImages.length} Files`}
            </button>
          )}
          {!pathname && <button className="hidden">Upload</button>}
        </div>

        {
          <div
            className={` ${isPreview ? "media-preview" : "media-preview-collapsed"} ${
              isDark ? "media-light-bg" : "darkMode"
            } `}
          >
            <div className="preview-text">
              <h3>{props.isPreview ? "Selected" : "Preview"}</h3>{" "}
              <span onClick={() => setIsPreview("")}>
                <i className="fa-solid fa-xmark cursor-pointer"></i>
              </span>
            </div>
            <div className={`'preview-item'`}>
              {getUniqueMedia?.split(".").pop() === "mp4" ? (
                <video
                  src={`${IMAGE_URL_BACKEND}/uploads/${getUniqueMedia}`}
                  className={`img-fluid w-100  ${
                    isDark ? "img-video-bg-light" : "img-video-bg-dark"
                  } `}
                  controls
                />
              ) : (
                <img
                  src={`${IMAGE_URL_BACKEND}/uploads/${getUniqueMedia}`}
                  className={`img-fluid w-100  ${
                    isDark ? "img-video-bg-light" : "img-video-bg-dark"
                  } `}
                  alt=""
                />
              )}
            </div>
          </div>
        }
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <div style={{ padding: "20px" }} className="flex flex-row justify-start">
          <form onSubmit={(e) => handleSearch(e)} style={{ width: "80%" }}>
            <input
              type="text"
              style={{
                width: "100%",
                margin: "5px 0 0 0",
                padding: "10px 10px 10px 35px",
                borderRadius: "5px",
                border: "none",
              }}
              placeholder="Search Images"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            ></input>
            <img
              src="/images/searchImg.svg"
              style={{
                position: "absolute",
                top: "35px",
                left: "28px",
                width: "24px",
              }}
            ></img>
          </form>
          <MediaDropdown
            isDark={isDark}
            searchPlat={searchPlat}
            setSearchPlat={setSearchPlat}
            setShow1={setShow1}
            dispatch={dispatch}
            clearImages={clearImages}
            setSearchText={setSearchText}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "7px",
            overflowY: "auto",
            margin: "20px 0",
            height: "500px",
            padding: "0 20px 20px 20px",
            breakInside: "avoid",
          }}
          id="hide-scrollbar"
        >
          {imagesList.images &&
            imagesList.images.map((i, ind) => {
              const imageUrl =
                imagesList.keyWord === "Pexels"
                  ? i.src.medium
                  : imagesList.keyWord === "Pixabay"
                  ? i.webformatURL
                  : i.urls.small;

              const isSelected = selectedImages1.includes(imageUrl);

              return (
                <div key={ind} style={{ position: "relative", display: "flex" }}>
                  <img
                    src={imageUrl}
                    style={{
                      maxWidth: "268px",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "10px",
                      border: isSelected ? "3px solid green" : "none",
                    }}
                    onClick={() =>
                      setSelectedImages1((prev) =>
                        isSelected ? prev.filter((url) => url !== imageUrl) : [...prev, imageUrl]
                      )
                    }
                  />
                  {isSelected && (
                    <div
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        backgroundColor: "black",
                        borderRadius: "50%",
                        padding: "6px",
                      }}
                    >
                      <img src="/images/tickImg.svg" alt="Tick" style={{ width: "20px" }} />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
        <div
          style={{
            padding: "0 20px 20px 20px",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "4px",
          }}
        >
          <button className="upload-btn" onClick={uploadArrayOfUrls}>
            Upload Media
          </button>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header closeButton>
          <div className="media-model-header" style={{ padding: "5px 10px 0px 10px" }}>
            <h2 className={isDark ? "" : "text-white"}>Select Media</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p
            style={{
              padding: "0px 10px 0px 10px",
              color: "gray",
              fontSize: "18px",
              marginBottom: "10px",
            }}
          >
            Design with :
          </p>
          <div style={{ padding: "0px 10px 0px 10px" }}>
            <div className="imageDivOuter">
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Pixabay");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
              >
                <img src="/images/Pixabay_new.png"></img>
              </div>
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Unsplash");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img
                  src={isDark ? "/images/UnsplashLogoLight.png" : "/images/UnsplashLogoLight.png"}
                  style={{ width: isDark ? "150px" : "220px" }}
                ></img>
              </div>
              <div
                className={`imageDivInner cursor-pointer ${
                  isDark
                    ? "hover:border-black hover:border-2"
                    : "hover:border-gray-400 hover:border-2"
                }`}
                onClick={() => {
                  setSearchPlat("Pexels");
                  setShow1(true);
                  dispatch(clearImages());
                  setSearchText("");
                }}
              >
                <img
                  src={isDark ? "/images/PexelsLogoLight.png" : "/images/PexelsLogoLight.png"}
                ></img>
              </div>
            </div>

            {isImgSelect ? (
              <>
                <div
                  className="media-model-body-btn"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "gray",
                  }}
                >
                  <p className="text-lg mb-2">Upload from Device : </p>
                  <button
                    onClick={() => {
                      handleFileSelect();
                    }}
                    className="upload-btn"
                    style={{ padding: "15px" }}
                  >
                    Select or Drag & Drop Media
                  </button>
                  <input
                    type="file"
                    accept="image/*, video/* "
                    id="fileInput"
                    multiple
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                </div>
              </>
            ) : (
              <>
                <div
                  className={`selected-media-model ${isDark ? "" : "selected-media-model-dark"} `}
                  style={{ margin: "20px 0", alignItems: "flex-start" }}
                >
                  {selectedFiles?.length >= 0
                    ? selectedFiles?.map((file, index) => (
                        <div
                          key={index}
                          className="selected-media-model-item d-flex justify-content-center"
                        >
                          {file?.name?.split(".").pop() === "mp4" ? (
                            <video
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              height={160}
                              className="w-100 "
                              controls
                            />
                          ) : (
                            <img
                              src={URL.createObjectURL(file)}
                              alt={file.name}
                              height={160}
                              className="w-100 cursor-pointer"
                            />
                          )}
                          <div className="cancel-model">
                            <i
                              className="fa-regular fa-trash-can media-delete-contain"
                              title="delete"
                              onClick={() => handleCancelselected(index)}
                            ></i>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
                <div className="d-flex justify-content-end">
                  {selectedFiles?.length >= 0 && (
                    <>
                      <button
                        onClick={() => {
                          handleFileSelect();
                        }}
                        className="upload-btn m-1"
                      >
                        +
                      </button>
                      <input
                        type="file"
                        accept="image/*, video/* "
                        id="fileInput"
                        multiple
                        onChange={handleImageChangemore}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                    </>
                  )}
                  {selectedFiles?.length >= 0 && (
                    <button onClick={handleSubmit} className="upload-btn m-1">
                      Upload Images
                    </button>
                  )}
                </div>

                {isProgress && (
                  <div className="progress mt-4">
                    <div
                      className="progress-bar progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      aria-valuenow="100"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      style={{ width: " 100%" }}
                    ></div>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDialog}
        onHide={closeDialog}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className={isDark ? "" : "isdark-model"}
        centered
      >
        <Modal.Header
          closeButton
          className={`${isDark ? "text-black" : "text-white"}`}
        ></Modal.Header>
        <Modal.Body>
          <p className="-mt-8" style={{ color: isDark ? "black" : "white" }}>
            Are you sure you want to delete the media file?
          </p>
          <div className="flex mt-3 items-center gap-3">
            <button
              className="gradient-button-new p-2 px-4 text-white rounded-md"
              onClick={deleteMediaModalYes}
            >
              Yes
            </button>
            <button
              className="gradient-button-new p-2 px-4 text-white rounded-md"
              onClick={() => setShowDialog(false)}
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Media;
