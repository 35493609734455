import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Slider } from '@mui/material';
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import Wordcloud from '@visx/wordcloud/lib/Wordcloud';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../api/API';

// Generate a gradient color palette
const generateGradientColors = (startColor, endColor, steps) => {
  const start = parseInt(startColor.slice(1), 16);
  const end = parseInt(endColor.slice(1), 16);
  const colors = [];

  for (let i = 0; i <= steps; i++) {
    const ratio = i / steps;
    const r = Math.round(((1 - ratio) * (start >> 16) + ratio * (end >> 16)) & 0xff);
    const g = Math.round(((1 - ratio) * ((start >> 8) & 0xff) + ratio * ((end >> 8) & 0xff)) & 0xff);
    const b = Math.round(((1 - ratio) * (start & 0xff) + ratio * (end & 0xff)) & 0xff);
    colors.push(`#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`);
  }

  return colors;
};

// Define gradient palette
const gradientColors = generateGradientColors('#8a51ff', '#39b2ff', 5);

export default function WordCloudComponent() {
  const { isDark } = useSelector((state) => state.common);
  const { userId, dateRange } = useSelector((state) => state.analytics);

  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxWords, setMaxWords] = useState(50); // Maximum number of words displayed

  useEffect(() => {
    const fetchData = async () => {
      try {
        const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
        const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);

        const response = await fetch(
          `${BASE_URL}post-analytics/wordcloud/${userId}?startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        );
        const data = await response.json();

        // Sort words by frequency and limit to `maxWords`
        const sortedWords = data.sort((a, b) => b.value - a.value).slice(0, maxWords);
        setWords(sortedWords);
      } catch (error) {
        console.error('Error fetching word cloud data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dateRange, userId, maxWords]);

  const fontScale = useMemo(() => {
    if (words.length === 0) return () => 0;
    return scaleLog({
      domain: [Math.min(...words.map((w) => w.value)), Math.max(...words.map((w) => w.value))],
      range: [15, 70],
    });
  }, [words]);

  const fontSizeSetter = useCallback((datum) => fontScale(datum.value), [fontScale]);

  return (
    <div
      className={`flex flex-col items-start justify-start w-full h-full transition-colors duration-300 ${!isDark ? 'text-white' : 'bg-black-50 text-black'
        }`}
      style={{ paddingRight: '16px' }} // Adding custom padding to the right side
    >
      {/* Title aligned to the extreme left */}
      <h3 className={`text-2xl font-bold mb-4 ${isDark ? 'text-black' : 'text-gray-300'} text-left w-full`}>
        Keyword Insights
      </h3>

      {/* Wordcloud with full-width */}
      <div
        className={`relative w-full p-6 h-[500px] rounded-md overflow-hidden mb-6 flex items-center justify-center ${!isDark ? 'bg-[#2D2D2D] text-white' : 'bg-gray-50 text-black'
          }`}
        style={{ padding: '16px' }}
      >
        {loading ? (
          <div className="flex items-center justify-center h-full text-gray-500">
            Loading...
          </div>
        ) : words.length === 0 ? (
          <div
            className={`flex items-center justify-center w-full h-full ${!isDark ? 'text-gray-200' : 'text-gray-700'
              }`}
          >
            No data available for the selected time frame.
          </div>
        ) : (
          <Wordcloud
            words={words}
            width={500} // Dynamically take the parent container's width
            height={500}
            fontSize={fontSizeSetter}
            font="Impact"
            padding={2}
            spiral="archimedean"
            rotate={0}
            random={() => 0.5}
          >
            {(cloudWords) =>
              cloudWords.map((w, i) => (
                <Text
                  key={w.text}
                  fill={gradientColors[i % gradientColors.length]} // Apply gradient colors
                  textAnchor="middle"
                  transform={`translate(${w.x}, ${w.y})`}
                  fontSize={w.size}
                  fontFamily={w.font}
                >
                  {w.text}
                </Text>
              ))
            }
          </Wordcloud>
        )}
      </div>

      {/* Centered Slider and Word Count */}
      <div className="w-full max-w-md mx-auto flex flex-col items-center">
        <Slider
          value={maxWords}
          min={10}
          max={50}
          step={10}
          onChange={(event, value) => setMaxWords(value)}
          aria-labelledby="max-words-slider"
          valueLabelDisplay="auto"
          sx={{
            width: 250,
            color: 'linear-gradient(126deg, #8a51ff, #39b2ff)', // Use gradient for slider
            '& .MuiSlider-thumb': {
              background: 'linear-gradient(126deg, #8a51ff, #39b2ff)',
            },
            '& .MuiSlider-track': {
              background: 'linear-gradient(126deg, #8a51ff, #39b2ff)',
            },
            '& .MuiSlider-rail': {
              backgroundColor: '#e0e0e0',
            },
          }}
        />
        <p className="mt-3 text-lg font-semibold text-center">
          Displaying Top <span className="text-blue-500">{maxWords}</span> Words
        </p>
      </div>
    </div>

  );
}